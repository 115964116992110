import PromoService from '@/services/PromoService'

const defaultPromo = () => ({
  _id: undefined,
  title: '',
  description: '',
  locations: [],
  nickname: undefined,
  inStorePromoCode: '',
  inStorePromoFile: '',
  offPremisePromoCode: '',
  offPremisePromoFile: '',
  offPremiseLocationUrl: '',
  expiresAt: undefined,
  expiresIn: undefined,
  source: '',
  termsAndConditions: undefined,
})

export default {
  namespaced: true,
  state: {
    promos: [],
    promo: defaultPromo(),
    sourceFilter: 'all',
    locations: [],
  },
  getters: {
    promos: (state) => state.promos,
    promo: (state) => state.promo,
    sourceFilter: (state) => state.sourceFilter,
    locations: (state) => state.locations,
    selectPromoName: (state) => (promoId) => {
      const promo = state.promos.find((p) => p._id === promoId)
      return promo ? promo.nickname || promo.title : ''
    },
    selectPromos: (state) => state.promos,
  },
  mutations: {
    setSourceFilter(state, filter) {
      state.sourceFilter = filter
    },
    setPromos(state, promos) {
      state.promos = promos
    },
    setTitle(state, title) {
      state.promo.title = title
    },
    setDescription(state, description) {
      state.promo.description = description
    },
    setLocations(state, locations) {
      state.promo.locations = locations
    },
    setExpiresAt(state, expiresAt) {
      state.promo.expiresAt = expiresAt
    },
    setInStorePromoCode(state, inStorePromoCode) {
      state.promo.inStorePromoCode = inStorePromoCode
    },
    setInStorePromoFile(state, inStorePromoFile) {
      state.promo.inStorePromoFile = inStorePromoFile
    },
    setOffPremisePromoCode(state, offPremisePromoCode) {
      state.promo.offPremisePromoCode = offPremisePromoCode
    },
    setOffPremisePromoFile(state, offPremisePromoFile) {
      state.promo.offPremisePromoFile = offPremisePromoFile
    },
    setOffPremiseLocationUrl(state, offPremiseLocationUrl) {
      state.promo.offPremiseLocationUrl = offPremiseLocationUrl
    },
    setExpiresIn(state, expiresIn) {
      state.promo.expiresIn = expiresIn
    },
    resetPromo(state) {
      state.promo = { ...defaultPromo() }
    },
    setPromo(state, promo) {
      state.promo = { ...promo, inStorePromoFile: '', offPremisePromoFile: '' }
    },
    setSource(state, source) {
      state.promo.source = source
    },
    SET_NICKNAME(state, nickname) {
      state.promo.nickname = nickname
    },
    SET_TERMS_AND_CONDITIONS(state, termsAndConditions) {
      state.promo.termsAndConditions = termsAndConditions
    },
  },
  actions: {
    setNickname({ commit }, nickname) {
      commit('SET_NICKNAME', nickname)
    },
    setTermsAndConditions({ commit }, termsAndConditions) {
      commit('SET_TERMS_AND_CONDITIONS', termsAndConditions)
    },
    async queryPromos({ rootGetters, commit }) {
      const companies =
        rootGetters['company/activeCompany']._id == 'ALL_COMPANIES'
          ? rootGetters['company/companies']
          : [rootGetters['company/activeCompany']]
      const companyIds = companies.map((company) => company._id)
      const result = await PromoService.list({
        filters: {
          companyIds,
        },
      })
      commit('setPromos', result.data.data.promos)
    },
    async queryPromosWithSource({ rootState, rootGetters, commit }) {
      const companies =
        rootGetters['company/activeCompany']._id == 'ALL_COMPANIES'
          ? rootGetters['company/companies']
          : [rootGetters['company/activeCompany']]
      const companyIds = companies.map((company) => company._id)
      const result = await PromoService.list({
        filters: {
          companyIds,
          disabled: false,
          sources: [rootState.promo.promo.source],
        },
      })
      commit('setPromos', result.data.data.promos)
    },
    async createPromo({ commit }, { promo }) {
      return PromoService.create({ promo })
    },
    async getUsedUniqueCode({ customerId, promoId, redemptionTimestamp }) {
      return await PromoService.usedCode({ customerId, promoId, redemptionTimestamp })
    },
  },
}
