<template>
  <div>
    <Pane borderRight>
      <PaneHeader :hidePane="hidePane" boldTitle id="timelineHeader">
        <template #close>
          <!-- this button is for the winback drawer for customer/survey pages -->
          <button
            class="btn btn-link mr-3 mt--2 p-0 text-dark"
            v-if="showClose"
            v-b-toggle.winback-sidebar
          >
            <ChevronLeftIcon size="1.5x"></ChevronLeftIcon>
          </button>
          <!-- this button is for the mobile responsive view of winback page -->
          <button
            class="btn btn-link mr-3 mt--2 p-0 text-dark"
            v-if="showDrawerClose"
            @click="$emit('close')"
          >
            <ChevronLeftIcon size="1.5x"></ChevronLeftIcon>
          </button>
        </template>
        <template #title>
          <span class="btn-pointer" v-b-tooltip.hover.bottom.v-primary :title="customerPhone">
            {{ title }}
          </span></template
        >
        <template #titleIcon>
          <UserXIcon size=".9x" class="text-danger mt--2 ml-3" v-if="blocked"></UserXIcon>
        </template>
        <template #actions v-if="selectActiveConversation">
          <div>
            <!-- resolve button -->
            <button
              id="resolveButton"
              class="btn btn-link py-0"
              @click="toggleResolve"
              v-b-tooltip="{
                title: selectActiveConversation.resolved
                  ? 'You can reopen this to keep a close eye on it.'
                  : 'You\'re safe to mark this as resolved. If they reply, it will automatically re-open.',
                placement: 'top',
                trigger: 'hover',
                variant: 'primary',
              }"
              :disabled="resolveLoading"
            >
              <b-spinner small variant="primary" v-if="resolveLoading" />
              <i
                class="fas fa-check fa-lg"
                :style="{ color: selectActiveConversation.resolved ? '#00d97e' : '#95aac9' }"
                v-else
              ></i>
            </button>

            <!-- addition actions ellipsis -->
            <!-- if more actions are added in the future the v-if check will need to be changed -->
            <div class="dropdown d-inline-block mx-2" v-if="selectIsPermitted('blockCustomer')">
              <a
                class="dropdown-ellipses dropdown-toggle"
                data-toggle="dropdown"
                href="#!"
                role="button"
              >
                <i class="fe fe-more-horizontal" style="color: #12263f"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-right" style="width: 250px !important">
                <a @click="toggleBlock" class="dropdown-item" href="javascript:;">
                  <div class="d-flex justify-content-between">
                    <span>{{ blocked ? 'Unblock Customer' : 'Block Customer' }}</span>
                    <UserXIcon size="1.25x" :class="{ 'text-danger': blocked }"></UserXIcon>
                  </div>
                </a>
              </div>
            </div>

            <!-- toggle customer details -->
            <div class="dropdown d-inline-block mx-2">
              <button id="resolveButton" class="btn btn-link p-0" @click="$emit('toggleDetails')">
                <SidebarIcon
                  size="1.4x"
                  style="color: #95aac9; transform: rotateY(180deg)"
                  class="mt--2 ml-4"
                ></SidebarIcon>
              </button>
            </div>
          </div>
        </template>
      </PaneHeader>

      <!-- celebration banner -->
      <div v-if="shouldCelebrate" class="celebration-banner form-control text-center text-light">
        <span>Great job responding quickly!</span>
      </div>

      <!-- timeline -->
      <div :style="{ height: innerPaneHeight - bannerHeight + 'px' }">
        <div v-if="selectActiveConversation" class="h-100" style="position: relative">
          <TimelineWrapper
            id="timelineWrapper"
            :bannerHeight="bannerHeight"
            :isSidebar="isSidebar"
          />
        </div>

        <!-- empty state -->
        <div
          v-else-if="showEmptyState"
          class="d-flex justify-content-center align-items-center h-100"
        >
          <div class="text-center">
            <i class="fas fa-inbox" style="font-size: 32px"></i>
            <div>No conversation selected</div>
          </div>
        </div>
      </div>
    </Pane>
  </div>
</template>

<script>
import { UserXIcon, ChevronLeftIcon, SidebarIcon } from 'vue-feather-icons'
import { trackEvent } from '@/lib/analytics'
import Pane from '../Components/Pane'
import PaneHeader from '../Components/PaneHeader'
import TimelineWrapper from '../Components/TimelineWrapper'
import phone from '@/filters/phone'
import { createNamespacedHelpers } from 'vuex'
import { checkName } from '@/lib/name'

const ConversationModuleV2 = createNamespacedHelpers('conversationV2')
const TimelineModuleV2 = createNamespacedHelpers('timelineV2')
const CustomerModule = createNamespacedHelpers('customer')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'MessageList',
  components: {
    Pane,
    PaneHeader,
    TimelineWrapper,
    UserXIcon,
    ChevronLeftIcon,
    SidebarIcon,
  },
  props: {
    showClose: {
      type: Boolean,
      default: false,
    },
    showDrawerClose: {
      type: Boolean,
      default: false,
    },
    hidePane: {
      type: Boolean,
      default: false,
    },
    isSidebar: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    innerPaneHeight: 0,
    bannerHeight: 0,
    celebrationTimeout: null,
    resolveLoading: false,
    resizeObserver: null,
  }),
  async mounted() {
    await this.setInnerPaneHeight()
    window.addEventListener('resize', this.setInnerPaneHeight)
    this.resizeObserver = new ResizeObserver(this.setInnerPaneHeight)
    this.resizeObserver.observe(document.querySelector('body'))
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setInnerPaneHeight)
    this.resizeObserver.disconnect()
  },
  computed: {
    ...ConversationModuleV2.mapGetters([
      'selectActiveConversationCustomer',
      'selectActiveConversation',
    ]),
    ...CompanyModule.mapGetters(['selectIsPermitted']),
    ...TimelineModuleV2.mapGetters(['selectIsWithinCelebrationTime']),
    title() {
      return this.selectActiveConversation
        ? checkName(this.selectActiveConversationCustomer?.name)
        : ''
    },
    customerPhone() {
      return this.selectActiveConversation
        ? phone(this.selectActiveConversationCustomer?.phone)
        : ''
    },
    shouldCelebrate() {
      return this.selectIsWithinCelebrationTime
    },
    blocked() {
      return this.selectActiveConversation && this.selectActiveConversation.company
        ? this.selectActiveConversationCustomer?.blocked?.includes(
            this.selectActiveConversation.company
          )
        : false
    },
    showEmptyState() {
      return !window.isNativeApp
    },
  },
  methods: {
    ...ConversationModuleV2.mapActions([
      'resolveConversation',
      'unresolveConversation',
      'setUnresolvedCount',
    ]),
    ...CustomerModule.mapActions(['blockCustomer', 'unblockCustomer']),
    async setInnerPaneHeight() {
      // the height should be calculated differently if it's the winback page vs when it's a drawer
      const windowHeight =
        this.$route.name === 'winback'
          ? document.querySelector('body').clientHeight
          : window.innerHeight
      this.innerPaneHeight = windowHeight - $('#timelineHeader')[0].offsetHeight
    },
    async toggleResolve() {
      this.resolveLoading = true
      try {
        if (this.selectActiveConversation.resolved) {
          await this.unresolveConversation(this.selectActiveConversation._id)
          trackEvent(this.$intercom, 'Unresolved')
        } else {
          await this.resolveConversation(this.selectActiveConversation._id)
          trackEvent(this.$intercom, 'Resolved')
        }
        this.setUnresolvedCount()
      } catch (error) {
        this.$notify({ text: 'Failed to toggle resolve', type: 'error' })
      }
      this.resolveLoading = false
    },
    async toggleBlock() {
      const companyId = this.selectActiveConversation.company
      const conversationId = this.selectActiveConversation._id
      const customerId = this.selectActiveConversationCustomer._id
      const customerObj = this.selectActiveConversationCustomer
      try {
        if (this.blocked) {
          await this.unblockCustomer({ companyId, customerId, conversationId, customerObj })
        } else {
          await this.blockCustomer({ companyId, customerId, conversationId, customerObj })
        }
      } catch (error) {
        this.$notify({ text: 'Failed to toggle blocking customer', type: 'error' })
      }
    },
  },
  watch: {
    shouldCelebrate: {
      handler(value) {
        if (value) {
          this.bannerHeight = 40.5
          this.celebrationTimeout = setTimeout(() => {
            this.bannerHeight = 0
            this.setInnerPaneHeight()
          }, 10000) // show for 10 seconds
        }
      },
    },
  },
  destroyed() {
    if (this.celebrationTimeout) {
      clearTimeout(this.celebrationTimeout)
    }
  },
}
</script>

<style lang="scss" scoped>
.message-builder {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.celebration-banner {
  border: 0px;
  background-color: var(--primary);
}
</style>
