<template>
  <div class="login-page">
    <div class="container">
      <div class="row align-items-center">
        <div
          class="d-none d-sm-none d-md-block col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0"
        >
          <div class="text-center">
            <img src="/theme/img/illustrations/happiness.svg" alt="..." class="img-fluid" />
          </div>
        </div>
        <div class="col-12 col-md-5 col-xl-4 order-md-1 my-5">
          <LoginForm @authenticated="authenticated" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/Auth/LoginForm'
import { initRouterView } from '@/router'
import { trackEvent } from '@/lib/analytics'

export default {
  name: 'LoginPage',
  components: {
    LoginForm,
  },
  methods: {
    authenticated() {
      initRouterView(this)
    },
  },
  mounted() {
    trackEvent(this.$intercom, 'Login')
  },
}
</script>

<style scoped lang="scss">
.login-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
