<template>
  <div>
    <div class="row">
      <form class="col-12">
        <div class="form-group mb-4">
          <label for="templateTitle">What's a good title for your template?</label>
          <input
            type="text"
            id="templateTitle"
            v-model="form.title"
            placeholder="Angry Customer"
            class="form-control"
            @input="updateTitle"
            v-b-popover.hover.topleft="'This is for your reference only.'"
            title="Title"
          />
        </div>
        <div class="form-group mb-2">
          <label for="templateTitle">What would you like your message to be?</label>
          <textarea
            v-model="form.body"
            @keyup="updateBody"
            ref="body"
            class="form-control sms text-message-orig"
            id="txt"
            maxlength="1000"
            name="content"
            :placeholder="`Hi <Customer First Name> - This is <Author First Name> from ${activeCompany.name} I'm truly sorry about your terrible experience. I'd like to make it right by...`"
            rows="4"
          ></textarea>
        </div>
        <div class="mb-4 mt-3">
          <h6
            class="header-pretitle mt"
            v-b-popover.hover.topleft="
              'Add these to your message, and they will be replaced with their real values. For example, <Customer First Name> could turn to Jane if you are sending a message to Jane.'
            "
          >
            Smart Tags <span class="fe fe-help-circle"></span>
          </h6>
          <a
            v-for="tag in templateTags"
            :key="tag.value"
            class="btn btn-white btn-sm mb-2 d-inline-block mr-2"
            href="javascript:;"
            @click="
              insertTagAtCursor(tag.value)
              updateBody()
            "
            >{{ tag.name }}</a
          >
        </div>
        <div class="mb-4 mt-3 form-group">
          <label for="templatePromo">
            Would you like to add an offer to this template?
            <span class="text-muted">(optional)</span>
          </label>
          <br />
          <small class="text-muted">
            Selected offer will be attached automatically to the template when used. "Prompt each
            time" will prompt the user to select which offer they would like to attach each time
            they use the template.
          </small>
          <AssignPromoDropdown class="mt-3" @promoSelected="handlePromoSelected" />
        </div>
        <div class="mb-4" v-if="form.body.includes('<')">
          <div class="alert alert-light" role="alert">
            <strong>Example:</strong>
            <span v-html="sampleOutput"></span>
          </div>
        </div>
        <div class="form-group mb-4">
          <label for="templateTitle">Location Visibility</label>
          <CustomerLocationFilter
            ref="locationAccessPicker"
            :wideMode="true"
            v-on:updateLocations="setLocations"
          />
        </div>
        <div>
          <a
            @click="saveTemplate"
            href="javascript:;"
            class="btn btn-block btn-lg btn-primary"
            :disabled="isLoading"
            :class="{ 'is-loading': isLoading }"
            >Save</a
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { templatize } from '@/lib/template'
const TemplateModule = createNamespacedHelpers('template')
const CompanyModule = createNamespacedHelpers('company')
const UserModule = createNamespacedHelpers('user')
const SmartTemplateTagsModule = createNamespacedHelpers('smartTemplateTags')
import CustomerLocationFilter from '@/components/Modules/Customer/CustomerFilters/CustomerLocationFilter'
import TemplateService from '@/services/TemplateService'
import AssignPromoDropdown from '../../Dropdowns/AssignPromoDropdown.vue'

export default {
  name: 'CreateTemplateForm',
  props: ['modal'],
  components: {
    AssignPromoDropdown,
    CustomerLocationFilter,
  },
  data: () => ({
    isLoading: false,
    form: {
      title: '',
      body: '',
    },
  }),
  computed: {
    ...TemplateModule.mapState(['template']),
    ...CompanyModule.mapState(['activeCompany']),
    ...UserModule.mapState(['onlineUser']),
    ...SmartTemplateTagsModule.mapState(['templateTags']),
    sampleOutput() {
      return templatize({
        body: this.form.body,
        company: this.activeCompany,
        customer: { name: 'Jane Doe' },
        user: this.onlineUser,
        location: this.template.locations.length ? this.template.locations[0] : {},
      })
    },
  },
  watch: {
    'form.body': {
      immediate: true,
      handler() {
        this.form.body = this.form.body.replace(/’/g, "'")
      },
    },
  },
  mounted() {
    if (this.template._id) {
      this.form.title = this.template.title
      this.form.body = this.template.body
      this.$refs.locationAccessPicker.reset(this.template.locations)
    }
  },
  methods: {
    ...TemplateModule.mapMutations(['setTitle', 'setBody', 'setLocations', 'resetTemplate']),
    ...TemplateModule.mapActions(['setPromo']),

    updateTitle() {
      this.setTitle(this.form.title)
    },
    updateBody() {
      this.setBody(this.form.body)
      this.$refs.body.focus()
    },
    async saveTemplate() {
      if (this.form.title.length < 3) {
        this.$notify({
          title: 'Failed to create template.',
          text: 'Please give your template a title.',
          type: 'error',
        })
        return
      }
      this.isLoading = true

      const template = this.template

      try {
        const response = await TemplateService.create({
          template: {
            ...template,
            company: this.activeCompany,
          },
        })
        this.isLoading = false
        this.resetTemplate()

        this.$notify({
          title: 'Launched!',
          text: `Your template is ready to go.`,
        })
        this.$refs.locationAccessPicker.reset()
        if (!this.modal) {
          this.$router.push({ name: 'templates' })
        } else {
          this.$emit('saved', response.body.template)
        }
      } catch (err) {
        this.isLoading = false
        this.errMessage = err.body.message
        this.$notify({
          title: 'Failed to create template.',
          text: 'Please contact us for assistance.',
          type: 'error',
        })
      }
    },
    handlePromoSelected(p) {
      const promo = {}
      if (p.noOffer) {
        promo.promptEachTime = false
        promo.promo = null
      } else if (p.promptEachTime) {
        promo.promo = null
        promo.promptEachTime = true
      } else {
        promo.promptEachTime = false
        promo.promo = p._id
      }
      this.setPromo(promo)
    },
    insertTagAtCursor(tagValue) {
      const textarea = document.getElementById('txt')
      if (textarea.selectionStart || textarea.selectionStart === 0) {
        var startPosition = textarea.selectionStart
        var endPosition = textarea.selectionEnd
        textarea.value =
          textarea.value.substring(0, startPosition) +
          tagValue +
          textarea.value.substring(endPosition, textarea.value.length)
        textarea.selectionEnd = startPosition + tagValue.length
      } else {
        textarea.value += tagValue
      }
      this.form.body = textarea.value
    },
  },
}
</script>

<style scoped lang="scss">
.form-group {
  margin-bottom: 0px;
}
#templateTitle {
  text-transform: capitalize;
}
</style>
