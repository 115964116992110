import Vue from 'vue'

export default {
  create(payload) {
    return Vue.http.post('integration', payload)
  },
  update(payload) {
    return Vue.http.post('integration/update', payload)
  },
  createIntegration({ companyId, integrationProviderId, locationId, ...payload }) {
    return Vue.http.post(
      `app-services/v2/integration/${companyId}/${locationId}/${integrationProviderId}`,
      payload
    )
  },
  updateIntegration({ integrationId, payload }) {
    return Vue.http.put(`app-services/v2/integration/${integrationId}`, payload)
  },
  updateIntegrationOauth(payload) {
    return Vue.http.put('app-services/v2/integration/oauth', payload)
  },
  getIntegration(integrationId) {
    return Vue.http.get(`app-services/v2/integration/${integrationId}`)
  },
  getChowNowLocations({ companyId }) {
    return Vue.http.get(`app-services/v2/integration/chownow/${companyId}/locations`)
  },
  getSquareLocations({ companyId, oAuthName }) {
    return Vue.http.get(
      `app-services/v2/integration/square/${companyId}/locations?oAuthName=${oAuthName}`
    )
  },
}
