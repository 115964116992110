<template>
  <div>
    <div class="my-3">
      <button class="btn btn-link pl-0" @click="back">
        <i class="fas fa-angle-left"></i> Back to Summary
      </button>
    </div>

    <!-- filters -->
    <InsightsDetailsFilters class="mb-4" @filtersApplied="refreshDetails" :key="refreshKey" />

    <!-- details -->
    <InsightsDetails :loading="loading" :wcLoading="wcLoading" @refreshDetails="refreshKey++" />
  </div>
</template>

<script>
import InsightsDetailsFilters from './InsightsDetailsFilters'
import InsightsDetails from './InsightsDetails'
import { createNamespacedHelpers } from 'vuex'

const InsightsDetailsModule = createNamespacedHelpers('insightsDetails')
const FeatureFlagModule = createNamespacedHelpers('featureFlag')

export default {
  name: 'DetailsView',
  components: {
    InsightsDetailsFilters,
    InsightsDetails,
  },
  data: () => ({
    loading: true,
    wcLoading: true,
    refreshKey: 0,
  }),
  mounted() {
    window.scrollTo(0, 0)
  },
  computed: {
    ...FeatureFlagModule.mapGetters(['isEnabled']),
  },
  methods: {
    ...InsightsDetailsModule.mapActions([
      'fetchPopulatedClassifications',
      'fetchClassifications',
      'fetchFeedbackCount',
      'fetchFeedbackCountBreakdown',
      'fetchOppositeSentimentClassifications',
      'fetchSummaries',
    ]),
    back() {
      this.$router.back()
    },
    refreshDetails() {
      this.loadBasic()
      this.isEnabled('AI_SUMMARY') ? this.loadSummaries() : (this.wcLoading = false)
    },
    async loadBasic() {
      this.loading = true
      await Promise.all([
        this.fetchPopulatedClassifications(),
        this.fetchClassifications(),
        this.fetchOppositeSentimentClassifications(),
        this.fetchFeedbackCount(),
        this.fetchFeedbackCountBreakdown(),
      ])
      this.loading = false
    },
    async loadSummaries() {
      this.wcLoading = true
      await this.fetchSummaries()
      this.wcLoading = false
    },
  },
}
</script>
