// Platform data

const PLATFORMS = {
  doordash: (sourceId) => ({
    name: 'DoorDash',
    img: `/theme/img/platforms/doordash.svg`,
    reviewUrl: `https://www.doordash.com/store/${sourceId}`,
    pageUrl: `https://www.doordash.com/store/${sourceId}`,
  }),
  google: (sourceId) => ({
    name: 'Google',
    img: `/theme/img/platforms/google.svg`,
    reviewUrl: `https://search.google.com/local/writereview?placeid=${sourceId}`,
    pageUrl: `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${sourceId}`,
  }),
  facebook: (sourceId) => ({
    name: 'Facebook',
    img: `/theme/img/platforms/facebook.svg`,
    reviewUrl: `https://www.facebook.com/pg/${sourceId}`,
    pageUrl: `https://www.facebook.com/pg/${sourceId}`,
  }),
  yelp: (sourceId) => ({
    name: 'Yelp',
    img: `/theme/img/platforms/yelp.svg`,
    reviewUrl: `https://www.yelp.com/writeareview/biz/${sourceId}`,
    pageUrl: `https://www.yelp.com/biz/${sourceId}`,
  }),
  foursquare: (sourceId) => ({
    name: 'Foursquare',
    img: `/theme/img/platforms/foursquare.svg`,
    reviewUrl: `https://foursquare.com/v/${sourceId}`,
    pageUrl: `https://foursquare.com/v/${sourceId}`,
  }),
  opentable: (sourceId) => ({
    name: 'OpenTable',
    img: `/theme/img/platforms/opentable.svg`,
    reviewUrl: `https://www.opentable.com/${sourceId}`,
    pageUrl: `https://www.opentable.com/${sourceId}`,
  }),
  tripadvisor: (sourceId) => ({
    name: 'TripAdvisor',
    img: `/theme/img/platforms/tripadvisor.svg`,
    reviewUrl: `https://www.tripadvisor.com/${sourceId}`,
    pageUrl: `https://www.tripadvisor.com/${sourceId}`,
  }),
}

export function getPlatformDetails({ source, sourceId, network, slug }) {
  return network ? PLATFORMS[network](slug) : PLATFORMS[source](sourceId)
}
