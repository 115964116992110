<template>
  <div>
    <div
      :class="!isMobile && 'p-3'"
      v-if="
        selectIsPermitted('winbackWriteAccess') ||
        (selectIsPermitted('noteWriteAccess') && internalNotesEnabled)
      "
    >
      <div v-if="nickname" class="pl-4 pb-1 font-weight-bold" style="color: var(--blue)">
        {{ nickname }}
        <span class="font-weight-light">is attached</span>
      </div>
      <div
        class="mb-0 message-builder-card"
        :class="{
          'p-2 pb-4 bg-white border-top': isMobile,
          'card p-3': !isMobile,
          'note-card': isNote,
        }"
        v-if="location && location.bandwidth"
      >
        <textarea-autosize
          v-model="message"
          @input="typing()"
          ref="messageInput"
          class="form-control message-input"
          style="border: none"
          :placeholder="'Reply here...'"
          rows="1"
          v-if="!isNote && !isGenerating"
        ></textarea-autosize>

        <div
          v-if="isGenerating"
          class="d-flex text-muted align-items-center px-3"
          style="height: 40px"
        >
          <b-spinner small variant="primary" class="mr-2" />
          Generating...
        </div>

        <QuillEditor v-model="message" :options="editorOptions" v-if="isNote" class="isNote" />

        <!-- action row -->
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center" style="padding-left: 11px">
            <button
              class="btn btn-link btn-sm action-icon p-0 mb--1"
              style="font-size: 20px"
              :style="{ color: AaIconShade }"
              @click="setIsNote(false)"
              title="Text Message"
              v-if="selectIsPermitted('winbackWriteAccess')"
            >
              Aa
            </button>
            <span title="Note">
              <Edit3Icon
                size="1.45x"
                class="action-icon"
                :style="{ color: noteIconShade }"
                @click="setIsNote(true)"
                v-if="internalNotesEnabled && selectIsPermitted('noteWriteAccess')"
              ></Edit3Icon>
            </span>
            <TemplateDropdownV2
              class="action-icon"
              ref="templateDropdown"
              @selected="addTemplate"
              @reset="resetTemplate"
              :includeModal="true"
              :locationId="selectActiveConversationLocation._id"
              :companyId="selectActiveConversationCompanyId"
              :key="selectActiveConversationCompanyId + selectActiveConversationLocation._id"
              v-if="!isNote"
            />
            <div v-if="showSelectedDropdown" @click="openPromo">
              <span class="d-flex align-items-center pr-1">
                <GiftIcon class="action-icon" size="1.4x" style="color: var(--blue)" />
              </span>
            </div>
            <PromoDropdownV2
              v-show="!showSelectedDropdown && selectIsPermitted('promosSendAccess')"
              class="action-icon"
              ref="promoDropdown"
              @selected="addPromo"
              @reset="resetPromo"
              :includeUniquePromos="true"
              :includeModal="true"
              source="winback"
              v-if="!isNote"
            />
            <LoyaltyDropdownV2
              class="action-icon"
              v-if="companyLoyaltyEnabled && !isNote"
              :customerId="selectActiveConversationCustomer._id"
              :companyId="selectActiveConversationCompanyId"
              :locationId="selectActiveConversationLocation._id"
              ref="loyaltyDropdown"
            />
            <button
              id="aiButton"
              class="btn btn-link p-0"
              @click="generateResponse"
              :disabled="isGenerating"
              v-if="showGenerateReply"
              title="Generate AI Response"
            >
              <inline-svg
                :src="'/img/ai-icon.svg'"
                :transformSource="(svg) => svg"
                width="23"
                height="23"
                aria-label="My image"
                class="action-icon"
              ></inline-svg>
            </button>
            <GiftCardButton
              class="action-icon"
              :key="refreshKey"
              @setAmount="setGiftCardAmount"
              v-if="giftCardEnabled && selectIsPermitted('giftCardWriteAccess') && !isNote"
            />
          </div>

          <div>
            <button class="btn btn-link send-btn mb--1" :disabled="sending" @click="processMessage">
              <span
                :class="!message ? 'inactive-send' : isNote ? 'active-add' : 'active-send'"
                v-if="!sending"
              >
                {{ isNote ? 'Add' : 'Send' }}
              </span>
              <b-spinner small variant="primary" v-else />
            </button>
          </div>
        </div>
      </div>

      <div class="card p-3 mb-0" v-else-if="location">
        <div class="alert alert-light fade show mb-0 text-center" role="alert">
          <strong>Uh-oh!</strong>
          SMS capabilities are not enabled for your
          {{ location.friendlyName || location.city }} location. <br />
          <a href="mailto:hello@ovationup.com">Contact us</a> to enable them.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { trackEvent } from '@/lib/analytics'
import { Edit3Icon, GiftIcon } from 'vue-feather-icons'
import TemplateService from '@/services/TemplateService'
import PromoDropdownV2 from '@/components/Modules/Dropdowns/PromoDropdownV2'
import TemplateDropdownV2 from '@/components/Modules/Dropdowns/TemplateDropdownV2'
import LoyaltyDropdownV2 from '@/components/Modules/Loyalty/LoyaltyDropdownV2'
import GiftCardButton from '@/components/Modules/GiftCard/Components/GiftCardButton'
import { createNamespacedHelpers } from 'vuex'
import { isMobile } from 'mobile-device-detect'

// quill imports
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill-mention/dist/quill.mention.css'
import Quill from 'quill'
import Mention from 'quill-mention'
Quill.register('modules/mentions', Mention)

const ConversationModuleV2 = createNamespacedHelpers('conversationV2')
const UserModule = createNamespacedHelpers('user')
const DataCoreModule = createNamespacedHelpers('dataCore')
const CompanyModule = createNamespacedHelpers('company')
const FeatureFlagModule = createNamespacedHelpers('featureFlag')
const TimelineModuleV2 = createNamespacedHelpers('timelineV2')

export default {
  name: 'MessageBuilder',
  components: {
    GiftIcon,
    PromoDropdownV2,
    TemplateDropdownV2,
    LoyaltyDropdownV2,
    QuillEditor: quillEditor,
    Edit3Icon,
    GiftCardButton,
  },
  data: () => ({
    message: '',
    promoId: undefined,
    highlightResolve: false,
    sending: false,
    isMobile,
    isNote: false,
    isGenerating: false,
    giftCardAmount: null,
    refreshKey: 0,
  }),
  mounted() {
    document.addEventListener('keydown', this.keyListener)
    if (!this.selectIsPermitted('winbackWriteAccess')) {
      this.setIsNote(true)
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyListener)
  },
  computed: {
    ...DataCoreModule.mapGetters(['selectCompanyById', 'selectLocationById', 'selectPromoById']),
    ...UserModule.mapGetters(['onlineUser', 'selectActiveCompanyUsersByConversationLocation']),
    ...ConversationModuleV2.mapGetters([
      'selectActiveConversation',
      'selectActiveConversationCustomer',
      'selectActiveConversationLocation',
      'selectActiveConversationCompanyId',
    ]),
    ...TimelineModuleV2.mapGetters(['selectLastEvent', 'selectLastActionableEvent']),
    ...CompanyModule.mapGetters(['activeCompany', 'selectIsPermitted']),
    ...FeatureFlagModule.mapGetters(['isEnabledV2']),
    showGenerateReply() {
      return (
        !this.isNote &&
        this.activeCompany?.settings?.winbackAiEnabled &&
        this.selectActiveConversation?.lastCommunication?.type === 'feedback'
      )
    },
    AaIconShade() {
      return this.isNote ? '#95aac9' : '#12263F'
    },
    noteIconShade() {
      return this.isNote ? '#12263F' : '#95aac9'
    },
    lastCommunicationType() {
      return this.selectActiveConversation?.lastCommunication?.type
    },
    internalNotesEnabled() {
      return this.activeCompany?.settings?.internalNotesEnabled
    },
    location() {
      return this.selectActiveConversationLocation
        ? this.selectLocationById(
            this.selectActiveConversationLocation._id || this.selectActiveConversationLocation
          )
        : {}
    },
    nickname() {
      const promo = this.selectPromoById(this.promoId)
      return this.isMobile && (promo?.nickname || promo?.title)
    },
    companyLoyaltyEnabled() {
      return this.selectCompanyById(this.selectActiveConversationCompanyId)?.loyalty?.enabled
        ? true
        : false
    },
    giftCardEnabled() {
      return this.selectActiveConversation?.location?.settings?.giftCardEnabled ? true : false
    },
    editorOptions() {
      let self = this
      return {
        modules: {
          mentions: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@'],
            source: function (searchTerm, renderList, mentionChar) {
              const values = self.selectActiveCompanyUsersByConversationLocation

              if (searchTerm?.length === 0) {
                renderList(values, searchTerm)
              } else {
                const matches = []
                for (let i = 0; i < values.length; i++)
                  if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
                    matches.push(values[i])
                renderList(matches, searchTerm)
              }
            },
            defaultMenuOrientation: 'top',
            dataAttributes: ['fullName'],
            renderItem: function (item) {
              return `
                <div style="white-space: nowrap">
                  <img
                    src="${item.avatar}"
                    alt="Avatar"
                    class="avatar"
                    style="display: ${item.avatar ? 'inline-block' : 'none'}"
                  >
                  <span
                    class="initials-avatar"
                    style="display: ${item.avatar ? 'none' : 'inline-block'}"
                  >
                    ${item.firstName[0] + item.lastName[0]}
                  </span>
                  <span style="vertical-align: middle;">
                    ${item.value}
                  </span>
                </div>
              `
            },
            isolateCharacter: true,
          },
          toolbar: false,
        },
        placeholder: 'Write an internal note...',
      }
    },
    showSelectedDropdown() {
      return !!(isMobile && this.promoId)
    },
  },
  methods: {
    ...ConversationModuleV2.mapActions(['sendReply', 'saveNote', 'generateReply']),
    keyListener(e) {
      if (e.key === 'Enter' && e.metaKey) {
        e.preventDefault()
        this.processMessage()
      }
    },
    typing() {},
    setIsNote(status) {
      this.isNote = status
      if (status === false) {
        this.message = ''
      }
    },
    async processMessage() {
      if (this.sending === true || !this.message) return
      this.sending = true
      if (this.isNote) {
        await this.createNote()
      } else {
        await this.sendMessage()
      }
      this.sending = false
    },
    async createNote() {
      const rawMentions = this.message.match(/data-value="[^"]*/gm) || []
      const mentions = rawMentions.reduce((acc, curr) => {
        acc.push(curr.replace(/data-value="/gm, ''))
        return acc
      }, [])
      if (mentions?.length) {
        const mentionedUsers = mentions.reduce((acc, curr) => {
          const match = this.selectActiveCompanyUsersByConversationLocation.find(
            (u) => u.value === curr
          )
          if (match) {
            acc.push(match)
          }
          return acc
        }, [])
        const mentionsRegex = new RegExp('(' + mentions.join('|') + ')', 'gm')
        const bracketedNamesMessage = this.message.replace(mentionsRegex, '{$1}')
        const formattedMessage = bracketedNamesMessage.replace(/<[^<]*>/gm, '').trim()
        await this.saveNote({
          conversationId: this.selectActiveConversation._id,
          text: formattedMessage,
          mentions: Array.from(mentionedUsers).map((uM) => ({
            userId: uM._id,
            atFallbackText: uM.value,
          })),
        })
      } else {
        const formattedMessage = this.message.replace(/<[^<]*>/gm, '').trim()
        await this.saveNote({
          conversationId: this.selectActiveConversation._id,
          text: formattedMessage,
          mentions: [],
        })
      }
      this.message = ''
      trackEvent(this.$intercom, 'Created an internal note', {
        userId: this.onlineUser?._id,
        companyId: this.activeCompany?._id,
      })
    },
    async sendMessage() {
      if (!this.message) return
      if (this.giftCardAmount) {
        const verified = await this.verifyGiftCard()
        if (!verified) return
      }
      try {
        await this.sendReply({
          promoId: this.promoId,
          message: this.message,
          giftCardAmount: this.giftCardAmount ? this.giftCardAmount * 100 : null,
        })
        trackEvent(this.$intercom, 'Sent_Reply')
        if (this.promoId) {
          trackEvent(this.$intercom, 'Sent_Promo')
        }
        if (this.giftCardAmount) {
          trackEvent(this.$intercom, 'Sent_Gift_Card')
        }

        this.resetAll()

        setTimeout(() => {
          this.$root.$emit('bv::show::tooltip', 'resolveButton')
        }, 2000)
      } catch (error) {
        this.$notify({ text: 'Failed to send message', type: 'error' })
      }
    },
    async verifyGiftCard() {
      const h = this.$createElement
      const titleVNode = h('div', [
        h('h2', { class: ['mb-0'], domProps: { innerHTML: 'Confirm Gift Card Creation' } }),
      ])
      const messageVNode = h('div', { class: ['text-center'] }, [
        'A gift card for ',
        h('strong', '$' + this.giftCardAmount),
        ' will be created.',
      ])
      let exportConfirmed = await this.$bvModal.msgBoxConfirm([messageVNode], {
        title: [titleVNode],
        size: 'md',
        okTitle: 'Create and Send',
        cancelTitle: 'Cancel',
        centered: true,
      })
      if (exportConfirmed) {
        return true
      } else {
        return false
      }
    },
    resetAll() {
      this.message = ''
      this.$refs.promoDropdown.reset()
      this.$refs.templateDropdown.reset()
      this.giftCardAmount = null
      this.refreshKey += 1
    },
    async setGiftCardAmount(value) {
      this.giftCardAmount = value
    },
    addPromo(promo) {
      this.promoId = promo._id
    },
    openPromo() {
      this.$refs.promoDropdown.reset()
      this.$refs.promoDropdown.openPromoDropdown()
    },
    resetPromo() {
      this.promoId = undefined
    },
    async addTemplate(template) {
      if (!template.promo && template.promptEachTime) {
        this.confirmAddPromo()
      } else if (template.promo) {
        this.$refs.promoDropdown.openPromoDropdown(template.promo)
      }
      const response = await TemplateService.interpolateTemplate({
        templateId: template._id,
        locationId: this.selectActiveConversationLocation._id,
        customerId: this.selectActiveConversationCustomer._id,
      })
      this.message = response.body.data.interpolatedTemplate
    },
    resetTemplate() {
      this.message = ''
    },
    confirmAddPromo() {
      this.$bvModal
        .msgBoxConfirm('Would you like to add an offer to this response?', {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          cancelVariant: 'default',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$refs.promoDropdown.openPromoDropdown()
          }
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            text: 'Something went wrong, please try again.',
          })
        })
    },
    async generateResponse() {
      this.isGenerating = true
      try {
        const { prompt, reply, usage, validated } = await this.generateReply({
          conversationId: this.selectActiveConversation._id,
        })
        if (!validated) {
          this.$bvModal.msgBoxConfirm(
            'Heads up! Our AI can sometimes have trouble with names. Please ensure that any names in the response are accurate before sending.',
            {
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'primary',
              cancelVariant: 'default',
              okTitle: 'Ok',
              cancelTitle: 'Cancel',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true,
            }
          )
        }
        this.message = reply
        trackEvent(this.$intercom, 'AI quick response', {
          reply,
          usage,
          prompt,
          companyId: this.activeCompany._id,
          conversationId: this.selectActiveConversation._id,
        })
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Error generating AI response',
        })
      }
      this.isGenerating = false
    },
  },
  watch: {
    selectLastEvent: {
      immediate: true,
      handler() {
        if (
          (this.selectLastActionableEvent?.type === 'internal-note-added' ||
            this.lastCommunicationType === 'note') &&
          !this.message &&
          this.selectIsPermitted('noteWriteAccess')
        ) {
          if (!this.isNote) {
            this.setIsNote(true)
          }
          const user = this.selectLastActionableEvent?.user
          if (user && user._id !== this.onlineUser._id) {
            this.message = `<p><span class="mention" data-index="0" data-denotation-char="@" data-value="${
              user.firstName + ' ' + user.lastName
            }"><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>${
              user.firstName + ' ' + user.lastName
            }</span></span> </p>`
          }
        }
      },
    },
    selectActiveConversation: {
      immediate: true,
      handler(val, oldVal) {
        if (val?._id !== oldVal?._id && this.selectIsPermitted('winbackWriteAccess')) {
          this.setIsNote(false)
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.action-icon {
  margin-right: 12px;
  color: #95aac9;
}
.action-icon:hover {
  color: #12263f !important;
}
::v-deep #aiButton > svg {
  fill: #95aac9 !important;
}
::v-deep #aiButton > svg:hover {
  fill: #12263f !important;
}
::v-deep #aiButton > svg > path {
  fill: inherit !important;
}
.message-builder {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.message-builder-card {
  position: relative;
}
.inactive-send {
  color: #95aac9 !important;
  font-weight: 400;
}
.active-send {
  color: #4184f4;
  font-weight: 700;
}
.active-add {
  color: #12263f;
  font-weight: 700;
}
.send-btn {
  width: 80px;
}
.note-card {
  background: #fdf3d8 !important;
  border-color: #fbe6b1 !important;
}
::v-deep .avatar {
  vertical-align: middle;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 3px;
}
::v-deep .initials-avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 10px;
  color: white;
  line-height: 25px;
  text-align: center;
  background: #b1c2d9;
  margin-right: 3px;
  vertical-align: middle;
}
::v-deep .ql-mention-list-container {
  overflow: scroll;
  max-height: 185px;
  width: auto;
  min-width: 270px;
  padding: 3px 0px 3px 0px;
}
::v-deep .ql-mention-list-item.selected {
  background-color: #edf2f9;
}
::v-deep .ql-mention-list-item {
  line-height: 38px;
}
::v-deep .ql-container.ql-snow {
  border: none;
}
::v-deep .ql-editor {
  border: none;
  background: #fdf3d8;
  min-height: 40px;
  padding: 8px 12px;
  font-size: 0.9375rem;
  font-family: 'Cerebri Sans', sans-serif;
}
::v-deep .ql-editor.ql-blank::before {
  color: #95aac9;
  font-style: normal;
}
::v-deep .mention {
  background-color: #fbe6b1;
}
</style>
