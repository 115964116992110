import { updateAnalyticsUserCompany } from '@/lib/analytics'
import CompanyService from '@/services/CompanyService'
import { get, cloneDeep } from 'lodash-es'
import Config from '@/config'

const PARTNER_IDS = {
  vendasta: Config.vendastaPartnerId,
}

export const setUserActiveCompanyId = (userId, companyId) =>
  window.localStorage.setItem(userId, companyId)

export const getUserActiveCompanyId = (userId) => window.localStorage.getItem(userId)

export const removeUserActiveCompanyId = (userId) => window.localStorage.removeItem(userId)

export default {
  namespaced: true,
  state: {
    activeCompany: undefined,
    activeSettingsTab: undefined,
    companies: [],
    textUsage: {
      // deprecated: use campaignSetups now
      textCount: 0,
      totalCount: 1000,
    },
    employees: [],
    companyLegalModalShown: false,
  },
  getters: {
    companies: (state) => state.companies,
    loyaltyEnabled(s) {
      const { loyalty = {} } = s.activeCompany || {}
      return loyalty ? !!loyalty.enabled : false
    },
    activePartnerId(s) {
      return (s.activeCompany || {}).partner || null
    },
    activePartnerName(_, g) {
      return PARTNER_IDS[g.activePartnerId] || null
    },
    activeCompany(state) {
      return state.activeCompany
    },
    selectEmployees(state) {
      return state.employees
    },
    selectEmployee: (state) => (id) => {
      return state.employees.find((e) => e._id === id)
    },
    selectCompanies(state) {
      return state.companies
    },
    selectOldestCompanyStartDate: (state) => (ids) => {
      let selectedCompanies = state.companies.filter((c) => ids.includes(c._id))
      if (!selectedCompanies.length) {
        selectedCompanies = state.companies
      }
      return selectedCompanies.reduce((previous, current) =>
        current.created < previous.created ? current : previous
      ).created
    },
    selectCompany: (state) => (id) => {
      return state.companies.find((c) => c._id === id)
    },
    selectCompanyLegalModalShown: (state) => {
      return state.companyLegalModalShown
    },
    selectShowNotesSettings: (state) => {
      return !!state.companies.filter((c) => c.settings.internalNotesEnabled).length
    },
    selectIsPermitted: (state, getters, rootState, rootGetters) => (element) => {
      const role = rootGetters['user/onlineUser']?.role
      const permissions = state.activeCompany?.permissions || {}
      if (!role) return false
      if (role === 'admin') return true
      if (!permissions || !permissions[element]) return true
      return permissions[element].includes(role)
    },
    selectInsightsCompanies: (state) => state.companies.filter((c) => c.insightsEnabled),
    selectActiveCompany: (state) => state.activeCompany,
    selectTextUsage: (state) => state.textUsage,
    selectRelevantCompanies: (state, getters, rootState, rootGetters) => {
      // returns for organizations if all_companies, else falls back to standard companies logic
      let companies = []
      const organizationCompanies = rootGetters['organization/selectOrganizationCompanies']
      if (state.activeCompany?._id === 'ALL_COMPANIES') {
        companies = organizationCompanies.length ? organizationCompanies : state.companies
      } else if (state.activeCompany?._id) {
        companies = [state.activeCompany] || []
      }
      return companies
    },
    selectAreResponsesEnabled: (state) => state.activeCompany?.settings?.responsesEnabled,
  },
  mutations: {
    setCompanies(state, companies) {
      state.companies = [...companies]
    },
    setActiveCompany(state, company) {
      state.activeCompany = company
    },
    setActiveSettingsTab(state, tab) {
      state.activeSettingsTab = tab
    },
    setTextUsage(state, usage) {
      state.textUsage = usage
    },
    setEmployees(state, employees) {
      state.employees = employees
    },
    SET_COMPANY_LEGAL_MODAL_SHOWN(state, companyLegalModalShown) {
      state.companyLegalModalShown = companyLegalModalShown
    },
    SET_ALL_COMPANIES(state) {
      state.activeCompany = { name: 'All Companies', _id: 'ALL_COMPANIES' }
    },
    SET_AUTO_RESPONSES_CONFIG(state, config) {
      state.activeCompany.settings.autoResponsesConfig = config
    },
  },
  actions: {
    async setAllCompanies({ commit }) {
      commit('SET_ALL_COMPANIES')
    },

    async refreshCompanyList({ state, rootState, commit, dispatch }) {
      const { onlineUser } = rootState.user

      if (!onlineUser) return

      const response = await CompanyService.list(rootState.user.onlineUser._id)
      const { companies } = response.body

      commit('setCompanies', companies)

      if (!state.activeCompany) {
        const activeCompanyId = getUserActiveCompanyId(onlineUser._id)

        if (activeCompanyId == 'ALL_COMPANIES') {
          dispatch('setActiveCompany', { name: 'All Companies', _id: 'ALL_COMPANIES' })
        } else {
          const companyMatch = companies.find((c) => c._id === activeCompanyId)
          const activeCompany = companyMatch ? companyMatch : companies[0]
          dispatch('setActiveCompany', activeCompany)
        }
      } else {
        if (state.activeCompany._id != 'ALL_COMPANIES') {
          const updatedActiveCompany = companies.find(
            (company) => company._id === state.activeCompany._id
          )
          dispatch('setActiveCompany', updatedActiveCompany)
        }
      }
    },
    async setActiveCompany({ rootState, commit, dispatch }, company) {
      const { onlineUser } = rootState.user

      const activeCompany = cloneDeep(company)
      commit('setActiveCompany', activeCompany)

      if (activeCompany) {
        if (onlineUser) {
          setUserActiveCompanyId(onlineUser._id, activeCompany._id)
        }

        updateAnalyticsUserCompany(this._vm.$intercom, activeCompany)
      }

      dispatch('analytics/refreshMetrics', {}, { root: true })
      dispatch('review/fetchOldestTimestamp', {}, { root: true })
    },
    async queryTextUsage({ state, commit }) {
      const response = await CompanyService.textUsage(state.activeCompany._id)
      const usage = { ...response.data }
      const creditBalance = state.activeCompany.textCreditBalance || 0
      usage.totalCount += creditBalance
      commit('setTextUsage', usage)
    },
    async fetchEmployees({ commit, rootState }) {
      const { _id: userId } = rootState.user.onlineUser
      const { _id: companyId } = rootState.company.activeCompany

      const response = await CompanyService.getTeam({ userId, companyId })

      if (response && response.body) {
        const { employees } = response.body
        commit('setEmployees', employees)
      }
    },
    async createCompany({ commit }, payload) {
      const response = await CompanyService.create(payload)
      return response
    },
    async createSquareOauth({ commit }, payload) {
      const response = await CompanyService.addOauth(payload)
      return response
    },
    async updateCompanyLegalData({ dispatch }, payload) {
      const loadingUuid = await dispatch(
        'addToLoader',
        { name: 'UPDATE_COMPANY_LEGAL_DATA' },
        { root: true }
      )
      let result = { success: true }
      try {
        const response = await CompanyService.updateCompanyLegal(payload)
        if (!response.body.success) {
          result.success = false
        }
      } catch (e) {
        if (e.body.message && e.body.message.startsWith('instance.body.zip')) {
          result.message = 'Sorry, Invalid Zip/Postal Code.'
        }

        if (e.body.message && e.body.message.startsWith('instance.body.corporateWebsite')) {
          result.message = 'Sorry, Invalid Corporate Website.'
        }
        result.success = false
      }
      await dispatch('removeFromLoader', loadingUuid, { root: true })
      return result
    },
    setCompanyLegalModalShown({ commit }, value) {
      commit('SET_COMPANY_LEGAL_MODAL_SHOWN', true)
    },

    async updateAutoResponsesConfig({ getters, commit }, autoResponsesConfig) {
      // get the active company
      const activeCompany = getters.selectActiveCompany
      const fields = {
        settings: { ...activeCompany.settings, autoResponsesConfig },
      }
      const response = await CompanyService.updateCompany({ companyId: activeCompany._id, fields })
      commit('SET_AUTO_RESPONSES_CONFIG', autoResponsesConfig)
    },

    async removeTemplateFromResponsesConfig({ getters, dispatch }, templateId) {
      const activeCompany = getters.selectActiveCompany
      const { autoResponsesConfig } = activeCompany.settings
      if (autoResponsesConfig) {
        let newConfig = {}
        for (const [key, value] of Object.entries(autoResponsesConfig)) {
          if (['delay', 'author'].includes(key)) {
            newConfig[key] = autoResponsesConfig[key]
          } else {
            newConfig[key] = value?.templates?.length
              ? {
                  ...value,
                  templates: value.templates.filter((t) => t !== templateId),
                }
              : value
          }
        }
        dispatch('updateAutoResponsesConfig', newConfig)
      }
    },
  },
}
