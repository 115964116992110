import moment from 'moment-timezone'
import { get } from 'lodash-es'
import UserService from '@/services/UserService'
import { updateAnalyticsUser, updateSentryUser } from '@/lib/analytics'
import WS from '../lib/websocket'

const [WSInstance, pushMessageHandler] = WS.connect()

let DAY_PARTS = {
  morning: { start: '12:00 AM', end: '11:59 AM' },
  afternoon: { start: '12:00 PM', end: '4:59 PM' },
  evening: { start: '5:00 PM', end: '11:59 PM' },
}

export default {
  namespaced: true,
  state: {
    WSInstance,
    onlineUser: undefined,
    needsInfo: undefined,
    userMap: {},
    authorizedUser: undefined,
  },
  getters: {
    onlineUser: (state) => state.onlineUser,
    selectOnlineUser: (state) => state.onlineUser,
    brandName(state) {
      return get(state.onlineUser, 'partner.name') || 'Ovation'
    },
    brandTextLogo(state) {
      return get(state.onlineUser, 'partner.textLogo') || '/theme/img/logo-text.svg'
    },
    brandSymbolLogo(state) {
      return get(state.onlineUser, 'partner.symbolLogo') || '/theme/img/logo-symbol.svg'
    },
    brandFaviconLogo(state) {
      return get(state.onlineUser, 'partner.faviconLogo') || '/img/favicon/favicon.ico'
    },
    appIsWhiteLabeled(state) {
      return !!get(state.onlineUser, 'partner._id')
    },
    phone(state) {
      return state.onlineUser.phone
    },
    selectUserSurveyTimeStamp(state, getters) {
      const user = getters.onlineUser
      return user?.surveyTimeStamp
    },
    selectUserCreated(state, getters) {
      const user = getters.onlineUser
      return user?.created
    },
    selectFeaturePreferences(state, getters) {
      const user = getters.onlineUser
      return user?.featurePreferences
    },
    isOvationEmployee(state, getters) {
      const user = getters.onlineUser
      return user && user?.email?.includes('ovation')
    },
    isOvationAdmin() {
      return window.localStorage.getItem('isOvationAdmin') === 'yes'
    },
    isWhiteLabeled() {
      // For local development
      if (window.location.origin.includes('localhost')) {
        return false
      }
      return !window.location.origin.toLowerCase().includes('ovationup')
    },
    isOptedOut: (state, getters) => (flag) => {
      const prefs = getters.selectFeaturePreferences
      return prefs?.[flag]?.disabled
    },
    showUserSurvey(state, getters) {
      const isOvationEmployee = getters.isOvationEmployee
      const lastSurveyTimeStamp = getters.selectUserSurveyTimeStamp
      const userCreateDate = getters.selectUserCreated
      const isWithinTimeRange = lastSurveyTimeStamp
        ? moment().diff(moment(lastSurveyTimeStamp), 'days') >= 60
        : userCreateDate
        ? moment().diff(moment(userCreateDate), 'days') >= 30
        : false
      return (
        !getters.isOvationAdmin &&
        !getters.isWhiteLabeled &&
        !isOvationEmployee &&
        isWithinTimeRange
      )
    },
    selectUserBrowserTimezone() {
      return new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
    },
    selectActiveCompanyUsersByConversationLocation: (state, getters, rootState, rootGetters) => {
      const companyId = rootGetters['company/activeCompany']._id
      const locationId = rootGetters['conversationV2/selectActiveConversation']?.location._id
      return Object.values(state.userMap).reduce((acc, curr) => {
        if (
          curr.companies.includes(companyId) &&
          (curr.role === 'admin' || curr.locations.includes(locationId))
        ) {
          curr.value = `${curr.firstName} ${curr.lastName}` // quill wants a value field to search off of
          acc.push(curr)
        }
        return acc
      }, [])
    },
    selectUserNameById: (state) => (id) => {
      const user = state.userMap[id]
      return user ? `${user.firstName} ${user.lastName}` : ''
    },
    selectUserMap: (state) => state.userMap,
    selectDayparts: (state) => state.onlineUser?.reports?.dayparts || DAY_PARTS,
    selectAuthorizedUser: (state) => state.authorizedUser,
  },
  mutations: {
    setOnlineUser(state, onlineUser) {
      const user = { ...onlineUser }
      state.onlineUser = user
      state.needsInfo = !user.firstName || (!user.phone && !user.email)
    },
    updateOnlineUser(state, update) {
      state.onlineUser = {
        ...state.onlineUser,
        ...update,
      }
    },
    SET_USER_MAP(state, userMap) {
      state.userMap = userMap
    },
    SET_DAYPARTS(state, dayparts) {
      if (state.onlineUser.reports) {
        state.onlineUser.reports.dayparts = dayparts
      } else {
        state.onlineUser.reports = { dayparts }
      }
    },
    SET_AUTHORIZED_USER(state, authorizedUser) {
      state.authorizedUser = authorizedUser
    },
  },
  actions: {
    async setDayparts({ commit, getters }, dayparts) {
      commit('SET_DAYPARTS', dayparts)
      await UserService.update({
        userId: getters.onlineUser._id,
        fields: {
          'reports.dayparts': dayparts,
        },
      })
    },

    async getOnlineUser({ commit }) {
      const response = await UserService.get()
      const { user } = response.body

      commit('setOnlineUser', user)
      updateAnalyticsUser(this._vm.$intercom, user)
      updateSentryUser(user)
    },

    pushWSMessageHandler({}, handler) {
      pushMessageHandler(handler)
    },

    async updateFeaturePreferences({ commit }, { id, payload }) {
      const result = await UserService.updateFeaturePreferences(id, payload)
      commit('updateOnlineUser', result?.data?.data)
    },

    async fetchCompaniesUsersList({ commit }) {
      const response = await UserService.listUsers()
      const { userMap } = response.body.data
      commit('SET_USER_MAP', userMap)
    },

    async fetchUserCompaniesAndLocations({ commit }) {
      const response = await UserService.getMe()
      // TODO: do something with the user, companie, and locations
      // const {
      //   data: { companies, locations, ...user },
      // } = response.data || {}
      return response
    },

    async fetchAuthorizedUser({ commit, dispatch }) {
      const response = await UserService.getMe()
      const user = response.body.data
      commit('SET_AUTHORIZED_USER', user)
      // set the user's orgs into the org store
      if (user.organizations?.length) {
        await dispatch('organization/setOrganizations', user.organizations, { root: true })
      }
    }
  },
}
