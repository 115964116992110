import InsightsService from '@/services/InsightsService'
import moment from 'moment-timezone'
import { isEqual } from 'lodash-es'

export default {
  namespaced: true,
  state: {
    categories: [],
    classifications: [],
    feedbackCount: 0,
    totalFeedbackCount: 0,
    negativeClassifications: [],
    companyWideNegativeClassifications: [],
    positiveClassificationsByCategory: {},
    negativeClassificationsByCategory: {},
    totalMentions: [],
    overallMentionsByDaypart: [],
    filters: {},
    feedbackCountBreakdown: {},
    topDrivers: [],
    summaries: {},
  },
  getters: {
    selectClassifications(state) {
      return state.classifications
    },
    selectPositiveClassificationsByCategory(state) {
      return state.positiveClassificationsByCategory
    },
    selectNegativeClassificationsByCategory(state) {
      return state.negativeClassificationsByCategory
    },
    selectFilters(state) {
      return state.filters
    },
    selectNonOtherCategories(state) {
      return state.categories.filter((c) => c.name !== 'Other')
    },
    selectOtherCategoryIds(state) {
      return state.categories.filter((c) => c.name === 'Other').map((c) => c._id)
    },
    selectParentCategoryIds(_, getters) {
      return getters.selectNonOtherCategories.reduce((acc, curr) => {
        if (!curr.path) acc.push(curr._id)
        return acc
      }, [])
    },
    selectCategoryParentMap(_, getters) {
      // TODO add support for paths multiple layers deep
      return getters.selectNonOtherCategories.reduce((acc, curr) => {
        if (!curr.path) {
          acc[curr._id] = null
        } else {
          acc[curr._id] = curr.path.split('/').pop()
        }
        return acc
      }, {})
    },
    selectCategoryNames(_, getters) {
      return getters.selectNonOtherCategories.reduce(
        (acc, curr) => {
          if (!curr.path) {
            acc.push(curr.name)
          }
          return acc
        },
        ['Overall']
      )
    },
    selectHeatmapCategories(_, getters) {
      return getters.selectNonOtherCategories.reduce(
        (acc, curr) => {
          if (!curr.path) acc.push(curr._id)
          return acc
        },
        ['Feedback']
      )
    },
    selectCategory: (state) => (id) => {
      return state.categories.find((el) => el._id === id)
    },
    selectCategoryName: (_, getters) => (id) => {
      return ['Overall', 'Feedback'].includes(id)
        ? id
        : getters.selectNonOtherCategories.find((el) => el._id === id)?.name
    },
    selectTopStrengths(_, getters) {
      const allPcs = getters.selectPositiveClassificationsByCategory
      const pcs = Object.fromEntries(
        Object.entries(allPcs).filter(([key]) => !getters.selectParentCategoryIds.includes(key))
      )
      let sorted = Object.keys(pcs).sort((a, b) => {
        return (
          pcs[b].classifications.length - pcs[a].classifications.length ||
          pcs[a].name.localeCompare(pcs[b].name)
        )
      })
      return sorted.slice(0, 3).map((id) => {
        const category = getters.selectCategory(id)
        if (category.path === '') {
          return { id: category._id, name: category.name, mentions: pcs[id].classifications.length }
        } else {
          const parentCategory = getters.selectCategory(category.path.split('/').pop())
          return {
            id: category._id,
            name: `${parentCategory.name}: ${category.name}`,
            mentions: pcs[id].classifications.length,
          }
        }
      })
    },
    selectTopIssues(_, getters) {
      const allNcs = getters.selectNegativeClassificationsByCategory
      const ncs = Object.fromEntries(
        Object.entries(allNcs).filter(([key]) => !getters.selectParentCategoryIds.includes(key))
      )
      let sorted = Object.keys(ncs).sort((a, b) => {
        return (
          ncs[b].classifications.length - ncs[a].classifications.length ||
          ncs[a].name.localeCompare(ncs[b].name)
        )
      })
      return sorted.slice(0, 3).map((id) => {
        const category = getters.selectCategory(id)
        if (category.path === '') {
          return { id: category._id, name: category.name, mentions: ncs[id].classifications.length }
        } else {
          const parentCategory = getters.selectCategory(category.path.split('/').pop())
          return {
            id: category._id,
            name: `${parentCategory.name}: ${category.name}`,
            mentions: ncs[id].classifications.length,
          }
        }
      })
    },
    selectTotalMentions(state) {
      return state.totalMentions
    },
    selectIsLocationFiltered(state) {
      return !!state.filters?.locations?.length
    },
    /**
     * If dayNumber is undefined it returns overall, else dynamically calculates by the dayNumber
     * @param {*} state
     * @returns
     */
    selectMentionsByDaypart: (state, getters, rootState, rootGetters) => (dayNumber) => {
      if (!dayNumber && dayNumber !== 0) {
        return state.overallMentionsByDaypart
      }

      let negativeDps = { morning: 0, afternoon: 0, evening: 0 }
      let positiveDps = { morning: 0, afternoon: 0, evening: 0 }
      const userDayparts = rootGetters['user/selectDayparts']
      const eveningStart = moment(userDayparts.evening.start, 'h:mm a').subtract(1, 'second')
      const morningEnd = moment(userDayparts.morning.end, 'h:mm a').add(1, 'minute')

      state.classifications.forEach((c) => {
        if (c.survey && moment(c.feedbackTextCreated).tz(c.locationTimezone).day() === dayNumber) {
          const localDateTime = moment(c.feedbackTextCreated).tz(c.locationTimezone)
          const comparisonTime = moment()
            .hour(localDateTime.hour())
            .minute(localDateTime.minute())
            .second(localDateTime.second())

          const daypart = comparisonTime.isAfter(eveningStart)
            ? 'evening'
            : comparisonTime.isBefore(morningEnd)
            ? 'morning'
            : 'afternoon'
          c.sentiment === 'NEGATIVE' ? (negativeDps[daypart] += 1) : (positiveDps[daypart] += 1)
        }
      })

      const totalNegative = negativeDps.morning + negativeDps.afternoon + negativeDps.evening
      const totalPositive = positiveDps.morning + positiveDps.afternoon + positiveDps.evening

      if (!totalNegative && !totalPositive) return

      return [
        {
          name: 'Negative',
          data: Object.keys(negativeDps).map(
            (dp) => (negativeDps[dp] / totalNegative).toFixed(2) * 100 || 0
          ),
        },
        {
          name: 'Positive',
          data: Object.keys(positiveDps).map(
            (dp) => (positiveDps[dp] / totalPositive).toFixed(2) * 100 || 0
          ),
        },
      ]
    },
    selectHeatmapSeries(state, getters, rootState, rootGetters) {
      const negativeClassifications = state.negativeClassifications
      let locations = state.filters.locations?.length
        ? rootGetters['location/selectFilteredLocations'](state.filters.locations)
        : state.filters.companies?.length
        ? rootGetters['location/selectInsightsLocationsByCompanies'](state.filters.companies)
        : rootGetters['location/selectInsightsLocations']

      const categories = getters.selectHeatmapCategories
      const parentMap = getters.selectCategoryParentMap
      const categoryIndexMap = categories.reduce((acc, cur, index) => {
        acc[cur] = index
        return acc
      }, {})

      // remove hidden locations and sort locations alphabetically
      locations = locations.filter((l) => !l.hideFromReports)
      locations.sort((a, b) => {
        const locationA = a.friendlyName || a.city
        const locationB = b.friendlyName || b.city
        return locationA > locationB ? -1 : locationB > locationA ? 1 : 0
      })

      const locationIds = locations.map((l) => l._id)

      let countsByLocation = {}
      for (let location of locations) {
        countsByLocation[location._id] = new Array(categories.length).fill(0)
      }

      // consolidate negative classifications into a single count under the parent categoryIds
      negativeClassifications.forEach((nc) => {
        nc.locations.forEach((ncl) => {
          if (locationIds.includes(ncl)) {
            countsByLocation[ncl][categoryIndexMap[parentMap[nc.category] || nc.category]] += 1
          }
        })
      })

      const overall = {
        name: getters.selectIsLocationFiltered ? 'Selected Locations' : 'All Locations',
        data: Object.entries(countsByLocation)
          .reduce((acc, curr) => {
            curr[1].forEach((val, index) => {
              acc[index] += val
            })
            return acc
          }, new Array(categories.length).fill(0))
          .map((d) => {
            if (state.feedbackCount) {
              const percentage = Math.ceil((d / state.feedbackCount) * 100)
              return percentage <= 100 ? percentage : 100
            } else {
              return 0
            }
          }),
        counts: Object.entries(countsByLocation).reduce((acc, curr) => {
          curr[1].forEach((val, index) => {
            acc[index] += val
          })
          return acc
        }, new Array(categories.length).fill(0)),
        totalCount: state.feedbackCount,
      }

      let companyWideCounts
      let companyWideOverall
      if (getters.selectIsLocationFiltered) {
        // consolidate negative classifications into a single count under parent categoryIds
        companyWideCounts = new Array(categories.length).fill(0)
        state.companyWideNegativeClassifications.forEach((cwnc) => {
          cwnc.locations.forEach((cwncl) => {
            companyWideCounts[categoryIndexMap[parentMap[cwnc.category] || cwnc.category]] += 1
          })
        })

        companyWideOverall = {
          name: 'Company-wide',
          data: companyWideCounts.map((d) => {
            if (state.totalFeedbackCount) {
              const percentage = Math.ceil((d / state.totalFeedbackCount) * 100)
              return percentage <= 100 ? percentage : 100
            } else {
              return 0
            }
          }),
          counts: companyWideCounts,
          totalCount: state.totalFeedbackCount,
        }
      }

      // format into data series for charts with data as an array of integer percentage values
      countsByLocation = Object.entries(countsByLocation).reduce((acc, curr) => {
        const locationFeedbackCount = getters.selectLocationFeedbackCount(curr[0])
        acc.push({
          name: curr[0],
          data: curr[1].map((d) => {
            if (locationFeedbackCount) {
              const percentage = Math.ceil((d / locationFeedbackCount) * 100)
              return percentage <= 100 ? percentage : 100
            } else {
              return 0
            }
          }),
          counts: curr[1],
          totalCount: locationFeedbackCount,
        })
        return acc
      }, [])

      const returnData = [
        ...countsByLocation,
        ...(locations?.length === 1 ? [] : [overall]),
        ...(companyWideOverall ? [companyWideOverall] : []),
      ]

      if (categories[0] === 'Feedback') {
        returnData.map((series) => (series.data[0] = -1)) // -1 means grab count instead of percentage in Heatmap
      }

      return returnData
    },
    selectFeedbackCount(state) {
      return state.feedbackCount
    },
    selectSubcategories: (state, getters) => (id) => {
      return getters.selectNonOtherCategories.filter((c) => new RegExp(id, 'i').test(c.path))
    },
    selectLocationFeedbackCount: (state) => (id) => {
      const counts = state.feedbackCountBreakdown[id]
      return counts ? counts.reviewCount + counts.surveyCount : 0
    },
    selectTopDrivers(state) {
      return state.topDrivers
    },
    selectSummaries: (state) => state.summaries,
  },
  mutations: {
    SET_CLASSIFICATIONS(state, classifications) {
      state.classifications = classifications
    },
    SET_NEGATIVE_CLASSIFICATIONS(state, negativeClassifications) {
      state.negativeClassifications = negativeClassifications
    },
    SET_COMPANY_WIDE_NEGATIVE_CLASSIFICATIONS(state, negativeClassifications) {
      state.companyWideNegativeClassifications = negativeClassifications
    },
    SET_POSITIVE_CLASSIFICATIONS_BY_CATEGORY(state, positiveClassificationsByCategory) {
      state.positiveClassificationsByCategory = positiveClassificationsByCategory
    },
    SET_NEGATIVE_CLASSIFICATIONS_BY_CATEGORY(state, negativeClassificationsByCategory) {
      state.negativeClassificationsByCategory = negativeClassificationsByCategory
    },
    SET_TOTAL_MENTIONS(state, totalMentions) {
      state.totalMentions = totalMentions
    },
    SET_OVERALL_MENTIONS_BY_DAYPART(state, overallMentionsByDaypart) {
      state.overallMentionsByDaypart = overallMentionsByDaypart
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    SET_FEEDBACK_COUNT(state, feedbackCount) {
      state.feedbackCount = feedbackCount
    },
    SET_TOTAL_FEEDBACK_COUNT(state, totalFeedbackCount) {
      state.totalFeedbackCount = totalFeedbackCount
    },
    SET_FEEDBACK_COUNT_BREAKDOWN(state, feedbackCountBreakdown) {
      state.feedbackCountBreakdown = feedbackCountBreakdown
    },
    SET_TOP_DRIVERS(state, drivers) {
      state.topDrivers = drivers
    },
    SET_SUMMARIES(state, summaries) {
      state.summaries = summaries
    },
  },
  actions: {
    async applyFilters({ commit, getters }, filters) {
      const { selectFilters } = getters
      if (isEqual(filters, selectFilters)) return
      commit('SET_FILTERS', filters)
    },

    async formatFilters({ getters, rootState, rootGetters }, { allLocations = false } = {}) {
      const { activeCompany } = rootState.company
      const filters = getters.selectFilters
      const payloadFilters = {
        createdAtRange: filters.dateRange || [
          moment().startOf('d').subtract(30, 'd').utc().format(),
          moment().endOf('day').utc().format(),
        ],
      }
      if (activeCompany._id === 'ALL_COMPANIES') {
        if (filters.companies) {
          payloadFilters.companyIds = Array.isArray(filters.companies)
            ? filters.companies
            : [filters.companies]
        } else {
          payloadFilters.companyIds = rootGetters['company/selectInsightsCompanies'].map(
            (c) => c._id
          )
        }
      } else {
        payloadFilters.companyIds = [activeCompany._id]
      }
      if (filters.locations && !allLocations) {
        payloadFilters.locationIds = Array.isArray(filters.locations)
          ? filters.locations
          : [filters.locations]
      } else {
        payloadFilters.locationIds = rootGetters['location/selectInsightsLocations']
          .filter((l) => !l.hideFromReports && !l.disabled)
          .map((l) => l._id)
      }
      if (filters.sources?.length) {
        payloadFilters.sources = filters.sources
      }
      return payloadFilters
    },

    async fetchCategories({ commit }) {
      const response = await InsightsService.fetchCategories()
      commit('SET_CATEGORIES', response.body.data.categories)
    },

    async fetchFeedbackCount({ commit, dispatch, getters }) {
      const response = await Promise.all([
        InsightsService.fetchFeedbackCount({ filters: await dispatch('formatFilters') }),
        ...(getters.selectIsLocationFiltered
          ? [
              InsightsService.fetchFeedbackCount({
                filters: await dispatch('formatFilters', { allLocations: true }),
              }),
            ]
          : []),
      ])
      commit('SET_FEEDBACK_COUNT', response[0].body.data)
      if (response[1]) {
        commit('SET_TOTAL_FEEDBACK_COUNT', response[1].body.data)
      }
    },

    async fetchFeedbackCountBreakdown({ commit, dispatch }) {
      const response = await InsightsService.fetchFeedbackCountBreakdown({
        filters: await dispatch('formatFilters', { allLocations: true }),
      })
      commit('SET_FEEDBACK_COUNT_BREAKDOWN', response.body.data)
    },

    async fetchClassifications({ dispatch, getters }) {
      const filters = getters.selectFilters
      let skip = 0
      const limit = 12000 // Adjust the limit as per your requirements
      let hasMoreData = true
      let allClassifications = []
      let safetyStop = 0
      while (hasMoreData) {
        const response = await InsightsService.fetchClassifications({
          filters: await dispatch('formatFilters', { allLocations: true }),
          ...(filters.keywords?.length ? { search: filters.keywords } : {}),
          limit,
          skip,
        })

        const classifications = response.body.data
        if (classifications.length === limit) {
          skip += limit // Prepare 'skip' for the next iteration
        } else {
          hasMoreData = false // Exit the loop if no data is returned
        }
        allClassifications = allClassifications.concat(classifications)
        if (safetyStop > 20) throw new Error('Stopping fetch classification endless loop')
        safetyStop++
      }

      await Promise.all([
        dispatch(
          'processClassifications',
          getters.selectIsLocationFiltered
            ? allClassifications.filter((c) => {
                if (c.location) {
                  return filters.locations?.includes(c.location)
                } else {
                  return !!filters.locations?.filter((l) => c.locations?.includes(l)).length
                }
              })
            : allClassifications
        ),
        ...(getters.selectIsLocationFiltered
          ? [dispatch('processAllClassifications', allClassifications)]
          : []),
      ])
    },

    async fetchSummaries({ commit, dispatch, getters }) {
      const filters = getters.selectFilters
      const response = await InsightsService.fetchSummaries({
        filters: await dispatch('formatFilters', { allLocations: true }),
        ...(filters.keywords?.length ? { search: filters.keywords } : {}),
      })
      const summaries = response.body.data
      commit('SET_SUMMARIES', summaries)
    },

    // this processes all classifications specifically for the 'company-wide' view
    // unfiltered by location. i.e. on the Heatmap
    async processAllClassifications({ commit, getters }, classifications) {
      const negativeClassifications = classifications.filter((c) => c.sentiment === 'NEGATIVE')

      commit('SET_COMPANY_WIDE_NEGATIVE_CLASSIFICATIONS', negativeClassifications)
    },

    // this can process all or a subset if there are locations filtered
    async processClassifications({ commit, getters, rootGetters }, classifications) {
      const categories = getters.selectNonOtherCategories
      // data for splitting
      let positiveClassificationsByCategory = {}
      let negativeClassificationsByCategory = {}
      categories.forEach((cat) => {
        positiveClassificationsByCategory[cat._id] = { name: cat.name, classifications: [] }
        negativeClassificationsByCategory[cat._id] = { name: cat.name, classifications: [] }
      })
      // data for total mentions
      let totalMentions = [
        { name: 'Negative', data: new Array(7).fill(0) },
        { name: 'Positive', data: new Array(7).fill(0) },
      ]
      // data for overall mentions by daypart
      let negativeDps = { morning: 0, afternoon: 0, evening: 0 }
      let positiveDps = { morning: 0, afternoon: 0, evening: 0 }
      const userDayparts = rootGetters['user/selectDayparts']
      const eveningStart = moment(userDayparts.evening.start, 'h:mm a').subtract(1, 'second')
      const morningEnd = moment(userDayparts.morning.end, 'h:mm a').add(1, 'minute')
      // data for heatmap
      let negativeClassifications = []

      // data for mention drivers
      let driverMap = {}
      let topDrivers = []
      for (let i = 0; i < 7; i++) {
        topDrivers.push({
          positive: [],
          negative: [],
        })
      }
      categories.forEach((cat) => {
        driverMap[cat._id] = {
          POSITIVE: new Array(7).fill(0),
          NEGATIVE: new Array(7).fill(0),
        }
      })

      // single pass over classifications for parsing
      classifications
        .filter((c) => !getters.selectOtherCategoryIds.includes(c.category))
        .forEach((c) => {
          // split positive/negative processing
          c.sentiment === 'POSITIVE'
            ? positiveClassificationsByCategory[c.category].classifications.push(c)
            : negativeClassificationsByCategory[c.category].classifications.push(c)

          // total mentions processing
          const seriesIndex = c.sentiment === 'NEGATIVE' ? 0 : 1
          if (c.review) {
            totalMentions[seriesIndex].data[moment(c.feedbackTextCreated).utc().day()] += 1
          } else {
            totalMentions[seriesIndex].data[
              moment(c.feedbackTextCreated).tz(c.locationTimezone).day()
            ] += 1
          }

          // drivers processing
          driverMap[c.category][c.sentiment][moment(c.feedbackTextCreated).utc().day()] += 1

          // heatmap processing
          if (c.sentiment === 'NEGATIVE') negativeClassifications.push(c)

          // overall mentions by daypart processing
          if (c.survey) {
            const localDateTime = moment(c.feedbackTextCreated).tz(c.locationTimezone)
            const comparisonTime = moment()
              .hour(localDateTime.hour())
              .minute(localDateTime.minute())
              .second(localDateTime.second())

            const daypart = comparisonTime.isAfter(eveningStart)
              ? 'evening'
              : comparisonTime.isBefore(morningEnd)
              ? 'morning'
              : 'afternoon'
            c.sentiment === 'NEGATIVE' ? (negativeDps[daypart] += 1) : (positiveDps[daypart] += 1)
          }
        })

      // top mention driver processing
      for (const catId in driverMap) {
        const driver = driverMap[catId]
        driver.POSITIVE.forEach((count, index) => {
          topDrivers[index].positive.push({ categoryId: catId, count })
        })
        driver.NEGATIVE.forEach((count, index) => {
          topDrivers[index].negative.push({ categoryId: catId, count })
        })
      }
      topDrivers = topDrivers.map((td) => {
        return {
          positive: td.positive
            .sort((a, b) => {
              return a.count > b.count ? -1 : b.count > a.count ? 1 : 0
            })
            .slice(0, 3),
          negative: td.negative
            .sort((a, b) => {
              return a.count > b.count ? -1 : b.count > a.count ? 1 : 0
            })
            .slice(0, 3),
        }
      })

      // overall mentions by daypart processing
      const totalNegative = negativeDps.morning + negativeDps.afternoon + negativeDps.evening
      const totalPositive = positiveDps.morning + positiveDps.afternoon + positiveDps.evening
      const overallMentionsByDaypart =
        totalNegative || totalPositive
          ? [
              {
                name: 'Negative',
                data: Object.keys(negativeDps).map(
                  (dp) => (negativeDps[dp] / totalNegative).toFixed(2) * 100 || 0
                ),
              },
              {
                name: 'Positive',
                data: Object.keys(positiveDps).map(
                  (dp) => (positiveDps[dp] / totalPositive).toFixed(2) * 100 || 0
                ),
              },
            ]
          : []

      // check if there is any data in totalMentions, assign empty array to show empty state
      totalMentions =
        totalMentions[0].data.every((i) => i === 0) && totalMentions[1].data.every((i) => i === 0)
          ? []
          : totalMentions

      commit('SET_CLASSIFICATIONS', classifications)
      commit('SET_NEGATIVE_CLASSIFICATIONS', negativeClassifications)
      commit('SET_POSITIVE_CLASSIFICATIONS_BY_CATEGORY', positiveClassificationsByCategory)
      commit('SET_NEGATIVE_CLASSIFICATIONS_BY_CATEGORY', negativeClassificationsByCategory)
      commit('SET_TOTAL_MENTIONS', totalMentions)
      commit('SET_OVERALL_MENTIONS_BY_DAYPART', overallMentionsByDaypart)
      commit('SET_TOP_DRIVERS', topDrivers)
    },
  },
}
