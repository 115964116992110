import Vue from 'vue'

export default {
  list(payload) {
    return Vue.http.post('survey/list', payload)
  },
  create(payload) {
    return Vue.http.post('survey/', payload)
  },
  export({ companyId, userId, filters, utcOffset }) {
    return Vue.http.post('survey/export', { companyId, userId, filters, utcOffset })
  },
  listSources(payload) {
    return Vue.http.post('app-services/v2/survey/list/sources', payload)
  },
}
