import * as Sentry from '@sentry/browser'
import mixpanel from 'mixpanel-browser'

const isProduction = process.env.NODE_ENV === 'production'

mixpanel.init(
  isProduction ? 'e54107fa08a7d19a59cdc788c7d0f4ca' : '75b21f0984e4a07824c75be0ae5b5743',
  { api_host: 'https://api.mixpanel.com' }
)

export function bootIntercom($intercom) {
  if (shouldHideIntercom()) return
  $intercom.boot()
}

export function shutdownIntercom($intercom) {
  if (shouldHideIntercom()) return
  $intercom.shutdown()
}

export function showIntercom($intercom) {
  if (shouldHideIntercom()) return
  $intercom.show()
}

export function updateAnalyticsUser($intercom, user) {
  if (isOvationAdmin() && isProduction) return
  mixpanel.identify(user._id)
  mixpanel.people.set({
    $email: user.email,
    $name: `${user.firstName} ${user.lastName}`,
    $first_name: user.firstName,
    $last_name: user.lastName,
    created: user.created,
    userId: user._id,
    $phone: user.phone,
  })
  if (shouldHideIntercom()) return
  $intercom.update({
    user_id: user._id,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    phone: user.phone,
  })
}

export function updateAnalyticsUserCompany($intercom, company) {
  if (isOvationAdmin() && isProduction) return
  mixpanel.people.set({
    company: company.name,
    companyId: company._id,
    companyCreated: company.created,
  })
  if (shouldHideIntercom()) return
  $intercom.update({
    company: {
      name: company.name,
      id: company._id,
      created_at: company.created,
    },
  })
}

export function trackEvent($intercom, event, eventData) {
  if (isOvationAdmin() && isProduction) return
  mixpanel.track(event, eventData)
  if (shouldHideIntercom()) return
  $intercom?.trackEvent(event)
}

export function updateSentryUser(user) {
  Sentry.configureScope((scope) => {
    scope.setUser({
      email: 'onlineUser.email',
      id: user._id,
    })
    scope.setExtra('name', `${user.firstName} ${user.lastName}`)
  })
}

const isOvationAdmin = () => window.localStorage.getItem('isOvationAdmin') === 'yes'
const isWhiteLabeled = () => !window.location.origin.toLowerCase().includes('ovationup')
const shouldHideIntercom = () => isOvationAdmin() || isWhiteLabeled()
