<template>
  <div id="top-banners" class="top-banner-container">
    <div
      v-for="(banner, index) in selectBanners"
      :key="index"
      :style="{ height: `${bannerHeight}px` }"
    >
      <!-- error banner -->
      <div v-if="banner.type === 'BUG'" class="bg-danger top-banner py-1">
        <span class="mr-3">{{ banner.message }}</span>
        <a class="text-white ml-3" :href="banner.redirectURL" v-if="banner.redirectURL"
          ><u>Learn more</u>
        </a>
        <!-- TODO: all close buttons ignore the banner.closable field, properly implement later -->
        <i class="fas fa-times float-right mr-3 mt-1" @click="dismissBanner(index)"></i>
      </div>

      <!-- info banner -->
      <div v-if="banner.type === 'INFO'" class="bg-primary top-banner py-1">
        {{ banner.message }}
        <a class="text-white ml-3" :href="banner.redirectURL" v-if="banner.redirectURL">
          <u>Learn more</u>
        </a>
        <i class="fas fa-times float-right mr-3 mt-1" @click="dismissBanner(index)"></i>
      </div>

      <!-- sale banner -->
      <div v-if="banner.type === 'SALE'" class="bg-success top-banner py-1">
        {{ banner.message }}
        <a class="text-white ml-3" :href="banner.redirectURL" v-if="banner.redirectURL">
          <u>Learn more</u>
        </a>
        <i class="fas fa-times float-right mr-3 mt-1" @click="dismissBanner(index)"></i>
      </div>

      <!-- other types banner -->
      <div
        v-if="banner.type === 'OTHER'"
        :style="{ backgroundColor: banner.color }"
        class="top-banner py-1"
      >
        {{ banner.message }}
        <a :href="banner.redirectURL" class="text-white ml-3" v-if="banner.redirectURL">
          <u>Learn more</u>
        </a>
        <i class="fas fa-times float-right mr-3 mt-1" @click="dismissBanner(index)"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const BannerModule = createNamespacedHelpers('banner')

export default {
  name: 'Banner',
  data: () => ({
    bannerHeight: 29,
  }),
  async mounted() {
    await this.fetchBanners()
    if (this.selectBanners.length) {
      $(window).on('resize', () => {
        this.formatScreen()
      })
      this.formatScreen()
    }
  },
  computed: {
    ...BannerModule.mapGetters(['selectBanners']),
  },
  methods: {
    ...BannerModule.mapActions(['fetchBanners', 'hideBanner']),
    formatBody() {
      document.querySelector('body').style['margin-top'] = `${$('#top-banners').height()}px`
      let height = `calc(100vh - ${$('#top-banners').height()}px)`
      document.querySelector('body').style['height'] = height
      document.querySelector('body').style['min-height'] = height
    },
    formatNav() {
      if ($(window).width() < 768) {
        document.getElementById('nav-main').style.top = '0px'
      } else {
        document.getElementById('nav-main').style.top = `${$('#top-banners').height()}px`
      }
    },
    formatScreen() {
      this.formatNav()
      this.formatBody()
    },
    dismissBanner(index) {
      this.hideBanner(index)
      $('#top-banners').height(`${$('#top-banners').height() - this.bannerHeight}px`)
      this.formatScreen()
    },
  },
}
</script>

<style scoped>
.top-banner {
  text-align: center;
  font-weight: bold;
  color: white;
}
.top-banner-container {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
}
</style>
