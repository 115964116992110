import TimelineService from '@/services/TimelineService'
import { scrollToElement, sortRatingBeforeFeedback, scrollToLastPosition } from '@/lib/utils'
import moment from 'moment-timezone'
import { dateMapper } from '@/lib/date'
import { throttle } from 'lodash-es'

const throttleTimelineEvents = throttle(
  async (dispatch) => {
    dispatch('fetchTimeline')
  },
  1000,
  { trailing: false }
)

const ACTIONABLE_EVENTS = [
  'internal-note-added',
  'left-feedback',
  'left-survey',
  'incoming-message',
  'outgoing-message',
]

const defaultState = () => ({
  showTimeline: false,
  lastEventId: null,
  events: [],
  eventsById: {},
  tempEvents: {},
  timelineEvents: [],
  loyalties: [],
  messages: [],
  orders: [],
  surveys: [],
  allPreviousEventsLoaded: false,
  tags: [],
  messageLimit: 30,
  timelineLoading: false,
  isWithinCelebrationTime: false,
})

export default {
  namespaced: true,
  state: {
    ...defaultState(),
  },
  getters: {
    selectMessageLimit: (state) => state.messageLimit,
    selectAllPreviousEventsLoaded: (state) => state.allPreviousEventsLoaded,
    selectFirstEventId: (state) => (state.events.length ? state.events[0]._id : null),
    selectLastEventId: (state) =>
      state.events.length ? state.events[state.events.length - 1]._id : null,
    selectLastEvent: (state) => state.events[state.events.length - 1],
    selectLastActionableEvent: (state) => {
      const actionableEvents = state.events.filter((e) => ACTIONABLE_EVENTS.includes(e.type))
      return actionableEvents.length ? actionableEvents[actionableEvents.length - 1] : null
    },
    selectTimelineEvents: (state) => state.timelineEvents,
    selectIsWithinCelebrationTime: (state) => state.isWithinCelebrationTime,
    selectTimelineLoading: (state) => state.timelineLoading,
    selectTempEventIds: (state) => (conversationId) =>
      Object.keys(state.tempEvents[conversationId] || {}),
    selectTempEvents: (state) => (conversationId) =>
      Object.values(state.tempEvents[conversationId] || {}),
    selectTempEventById: (state, getters, rootState, rootGetters) => (id) =>
      state.tempEvents?.[rootGetters['conversationV2/selectActiveConversation']?._id]?.[id],
    selectEventById: (state) => (id) => state.eventsById[id],
  },
  mutations: {
    RESET_TIMELINE_DATA(state) {
      state.events = []
      state.loyalties = []
      state.messages = []
      state.orders = []
      state.surveys = []
      state.tags = []
      state.allNextEventsLoaded = false
      state.allPreviousEventsLoaded = false
    },
    RESET_STATE(state) {
      Object.assign(state, defaultState())
    },
    SET_ALL_PREVIOUS_EVENTS_LOADED(state, allPreviousEventsLoaded) {
      state.allPreviousEventsLoaded = allPreviousEventsLoaded
    },
    SET_TAGS(state, tags) {
      state.tags = tags
    },
    SET_EVENTS(state, events) {
      state.eventsById = events.reduce(
        (acc, curr) => ({
          ...acc,
          [curr._id]: curr,
        }),
        state.eventsById
      )
      const newEventIds = events.map((event) => event._id)
      let rawEvents = [
        ...state.events.filter((event) => !newEventIds.includes(event._id)),
        ...events,
      ].sort((a, b) => moment(a.created) - moment(b.created))

      const sortedEvents = []
      rawEvents.forEach((event) => {
        // check if the array is empty
        if (sortedEvents.length === 0) {
          const obj = {}
          Object.assign(obj, {
            timestamp: dateMapper(event.created),
            events: [event],
          })
          sortedEvents.push(obj)
        } else {
          // check if the index item exists in the array
          const index = sortedEvents.findIndex((e) => e.timestamp === dateMapper(event.created))
          if (index > -1) {
            let item = sortedEvents[index]
            item = { ...item, events: [...item.events, event] }
            // update the item at index
            sortedEvents[index] = item
          } else {
            const obj = {}
            Object.assign(obj, {
              timestamp: dateMapper(event.created),
              events: [event],
            })
            // push the new object to array
            sortedEvents.push(obj)
          }
        }
      })
      state.events = rawEvents
      state.timelineEvents = sortRatingBeforeFeedback(sortedEvents)
    },
    CLEAR_TEMP_EVENT(state, { conversationId, eventId }) {
      const update = {
        ...state.tempEvents,
      }
      if (update?.[conversationId]?.[eventId]) {
        delete update[conversationId][eventId]
      }
      state.tempEvents = update
    },
    SET_TEMP_EVENTS(state, events) {
      state.tempEvents = events.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.conversation]: {
            ...(acc[curr.conversation] || {}),
            [curr._id]: curr,
          },
        }),
        state.tempEvents
      )
    },
    SET_TIMELINE_LOADING(state, status) {
      state.timelineLoading = status
    },
    SET_IS_WITHIN_CELEBRATION_TIME(state, status) {
      state.isWithinCelebrationTime = status
    },
  },
  actions: {
    async clearTempEvent({ commit }, { conversationId, eventId }) {
      commit('CLEAR_TEMP_EVENT', { conversationId, eventId })
    },
    async clearTimelineData({ commit }) {
      commit('RESET_TIMELINE_DATA')
    },

    async resetAllTimelineData({ commit }) {
      commit('RESET_STATE')
    },

    async setIsWithinCelebrationTime({ commit }, status) {
      commit('SET_IS_WITHIN_CELEBRATION_TIME', status)
    },

    async fetchTimeline({ commit, dispatch, getters, rootGetters }) {
      commit('SET_TIMELINE_LOADING', true)
      const { selectMessageLimit, selectFirstEventId, selectAllPreviousEventsLoaded } = getters
      const selectActiveConversation = rootGetters['conversationV2/selectActiveConversation']
      const initialLoad = !!!selectFirstEventId

      if (!selectActiveConversation) return
      const conversationIdBefore = selectActiveConversation._id.toString()

      if (
        selectActiveConversation.opened &&
        !selectActiveConversation.opened.includes(rootGetters['dataCore/selectActiveUserId'])
      ) {
        await dispatch(
          'conversationV2/open',
          { conversationId: selectActiveConversation._id },
          { root: true }
        )
      }

      if (selectAllPreviousEventsLoaded) {
        return
      }

      let payload = {
        filters: {
          customerIds: [selectActiveConversation.customer._id || selectActiveConversation.customer],
          companyIds: [selectActiveConversation.company],
          locationIds: rootGetters['location/selectActiveLocations'].map((l) => l._id),
        },
        ...(selectFirstEventId
          ? { startingAnalyticEventId: selectFirstEventId, previousLimit: selectMessageLimit }
          : { previousLimit: selectMessageLimit }),
      }

      const response = await TimelineService.fetchTimeline(payload)

      if (response.body.success !== true) {
        throw response.body
      }

      // make sure conversation hasn't changed during the request wait time
      const conversationIdAfter =
        rootGetters['conversationV2/selectActiveConversation']._id.toString()
      if (conversationIdBefore !== conversationIdAfter) return

      if (response.body.data.analyticEvents.length < selectMessageLimit) {
        commit('SET_ALL_PREVIOUS_EVENTS_LOADED', true)
      } else {
        commit('SET_ALL_PREVIOUS_EVENTS_LOADED', false)
      }

      const { analyticEvents, customerOrders, surveys, messages, loyalties } = response.body.data
      commit('SET_EVENTS', analyticEvents)

      // dispatch('fetchTags') // this will get implemented with the customer details panel

      if (initialLoad) {
        dispatch('scrollToLastEvent')
      }

      commit('SET_TIMELINE_LOADING', false)
    },

    async scrollToLastEvent({ getters }) {
      scrollToElement(`timeline-${getters.selectLastEventId}`)
    },

    setIsWithinCelebrationTime({ commit }, status) {
      commit('SET_IS_WITHIN_CELEBRATION_TIME', status)
    },

    async setTempTimelineEvent({ commit }, event) {
      commit('SET_TEMP_EVENTS', [event])
    },

    async processTimelineEventAdded({ dispatch, rootGetters }, analyticEvent) {
      const activeConversation = rootGetters['conversationV2/selectActiveConversation']
      if (!activeConversation || !analyticEvent || analyticEvent.type === 'order') {
        return
      }

      const activeConversationId = activeConversation._id
      const { conversation, company, location, customer } = analyticEvent
      const eventCustomerId = customer?._id
      const conversationCustomerId = activeConversation?.customer?._id
      const eventLocationId = location?._id
      const conversationLocationId = activeConversation?.location?._id
      const eventCompanyId = company
      const conversationCompanyId = activeConversation.company
      const eventConversationId = conversation

      if (
        eventConversationId === activeConversationId ||
        (conversationCustomerId === eventCustomerId &&
          eventCompanyId === conversationCompanyId &&
          (!eventLocationId || eventLocationId === conversationLocationId))
      ) {
        dispatch('appendTimelineEvent', { analyticEvent, conversation: activeConversation })
      }
    },

    appendTimelineEvent({ commit, getters }, { analyticEvent, conversation }) {
      if (analyticEvent.type === 'outgoing-message') {
        const tempEventId = `temp-${analyticEvent.message?._id}`
        if (
          analyticEvent?.message?._id &&
          getters.selectTempEventIds(conversation._id).includes(tempEventId)
        ) {
          commit('CLEAR_TEMP_EVENT', { conversationId: conversation._id, eventId: tempEventId })
        }
      }
      if (analyticEvent.type === 'internal-note-added') {
        const tempEventId = `temp-${analyticEvent.note?._id}`
        if (
          analyticEvent?.note?._id &&
          getters.selectTempEventIds(conversation._id).includes(tempEventId)
        ) {
          commit('CLEAR_TEMP_EVENT', { conversationId: conversation._id, eventId: tempEventId })
        }
      }
      if (analyticEvent.type === 'sent-gift-card') {
        const tempEventId = `temp-${analyticEvent.giftCard?._id}`
        if (
          analyticEvent?.giftCard?._id &&
          getters.selectTempEventIds(conversation._id).includes(tempEventId)
        ) {
          commit('CLEAR_TEMP_EVENT', { conversationId: conversation._id, eventId: tempEventId })
        }
      }
      commit('SET_EVENTS', [analyticEvent])
      scrollToElement(`timeline-${getters.selectLastEventId}`)
    },
  },
}
