import DashboardService from '@/services/DashboardService'
import { isEqual } from 'lodash-es'
import moment from 'moment'

const timeConversion = (minutes) => {
  let hours
  let days
  let remainingMinutes
  const oneDayMinutes = 1440 // 24 * 60

  if (minutes < 0) {
    minutes *= -1
    return `-${timeConversion(minutes)}`
  } else if (minutes < 60) {
    return `${minutes}m`
  } else if (minutes > 60 && minutes < oneDayMinutes) {
    hours = Math.floor(minutes / 60)
    remainingMinutes = minutes % 60
    return `${hours}h ${remainingMinutes}m`
  } else {
    days = Math.floor(minutes / 60 / 24)
    remainingMinutes = minutes % oneDayMinutes
    hours = Math.floor(remainingMinutes / 60)
    remainingMinutes = remainingMinutes % 60
    return `${days}d ${hours}h`
  }
}

export default {
  namespaced: true,
  state: {
    showFiltersBar: false,
    refreshKey: 0,
    filters: {},
    widgetList: [
      // Survey
      {
        _id: 1,
        title: 'Surveys Sent',
        type: 'SURVEY_SENT_COUNT',
        size: 'x-small',
        tooltip: 'The number of surveys sent in selected date range.',
      },
      {
        _id: 2,
        title: 'Response Rate',
        type: 'SURVEY_RESPONSE_RATE',
        size: 'x-small',
        tooltip:
          'The number of surveys with responses to initial feedback divided by the number of surveys with feedback.',
      },
      {
        _id: 3,
        title: 'Average Score',
        type: 'SURVEY_AVERAGE_RATING',
        size: 'x-small',
        tooltip: 'The sum of all survey ratings divided by the number of surveys taken.',
      },
      {
        _id: 4,
        title: 'Surveys Taken',
        type: 'SURVEY_COMPLETED_COUNT',
        size: 'x-small',
        tooltip: 'The number of surveys completed with at least a rating (possibly not feedback).',
      },
      {
        _id: 5,
        title: 'Avg Response Time',
        type: 'SURVEY_AVG_RESPONSE_TIME',
        size: 'x-small',
        tooltip:
          'The average amount of time a survey with feedback was waiting for a response (in minutes).',
      },
      {
        _id: 6,
        title: 'Survey Scores',
        type: 'SURVEY_SCORES',
        size: 'small',
        tooltip: 'The total counts of each rating score in a given period of time.',
      },
      {
        _id: 7,
        title: 'Customers Added',
        type: 'CUSTOMER_CREATED_COUNT',
        size: 'x-small',
        tooltip: 'The number of new customers created in a given time period.',
      },
      {
        _id: 8,
        title: 'Reviews Left',
        type: 'REVIEW_CREATED_COUNT',
        size: 'x-small',
        tooltip: 'The number of new reviews created in a given time period.',
      },
      {
        _id: 9,
        title: 'Recent Feedback',
        type: 'SURVEY_RECENT_FEEDBACK',
        size: 'large',
        tooltip: 'The number of new reviews created in a given time period.',
      },
      {
        _id: 10,
        title: 'Satisfaction by Source',
        type: 'SURVEY_SATISFACTION_BY_SOURCE',
        size: 'small',
        tooltip: 'The average rating of each survey channel',
      },
      {
        _id: 11,
        title: 'Customers Saved',
        type: 'CUSTOMER_SAVED',
        size: 'x-small',
        tooltip: 'The number of customers saved after leaving a low survey score.',
      },
      {
        _id: 12,
        title: 'Clicks to Review',
        type: 'REVIEW_CLICKS',
        size: 'x-small',
        tooltip: 'The number of times a customer clicked a link to leave a review.',
      },
      {
        _id: 13,
        title: 'Unresponded Surveys',
        type: 'SURVEY_UNRESPONDED',
        size: 'x-small',
        tooltip: 'TODO',
      },
      {
        _id: 14,
        title: 'Customers Opted In',
        type: 'CUSTOMER_OPTED_IN',
        size: 'x-small',
        tooltip: 'TODO',
      },
      {
        _id: 15,
        title: 'Average Online Rating',
        type: 'REVIEW_AVERAGE_ONLINE_RATING',
        size: 'x-small',
        tooltip: 'The average online rating since joining Ovation',
      },
      {
        _id: 16,
        title: 'Reviews Per Month',
        type: 'REVIEWS_PER_MONTH',
        size: 'x-small',
        tooltip: 'The average number of reviews per month with Ovation.',
      },
      {
        _id: 17,
        title: 'Current Reviews and Ratings',
        type: 'REVIEW_CURRENT_AND_RATINGS',
        size: 'small',
        tooltip: 'The up to date reviews and ratings by platform.',
      },
      {
        _id: 18,
        title: 'Reviews and Ratings',
        type: 'REVIEW_AND_RATINGS',
        size: 'medium',
        tooltip: 'The counts and average ratings over a given time period.',
      },
      {
        _id: 19,
        title: 'Survey Volume by Source',
        type: 'SURVEY_VOLUME_BY_SOURCE',
        size: 'small',
        tooltip: 'The amount of surveys for each source.',
      },
      {
        _id: 20,
        title: 'Satisfaction by Dayparts',
        type: 'SURVEY_DAYPARTS',
        size: 'medium',
        tooltip: 'Satisfaction broken down by hour windows throughout the day.',
      },
    ],
    widgetData: {}, // format is widgetData: {[id]: {value, comparison}}
    trends: {},
    granularity: '',
  },
  getters: {
    selectRefreshKey(state) {
      return state.refreshKey
    },
    selectFilters(state) {
      return state.filters
    },
    selectShowFiltersBar(state) {
      return state.showFiltersBar
    },
    selectComparisonPercent: (state) => (id) => {
      const widgetData = state.widgetData[id]
      const { value, comparison } = widgetData
      return comparison > 0
        ? Math.round(((value - comparison) / comparison) * 100).toString() + '%'
        : value === 0
        ? '0'
        : ''
    },
    selectComparisonNumber: (state) => (id) => {
      const widgetData = state.widgetData[id]
      const { value, comparison } = widgetData
      return comparison > 0 ? (value - comparison).toFixed(1).toString() : value === 0 ? '0' : ''
    },
    selectComparisonTime: (state) => (id) => {
      const widgetData = state.widgetData[id]
      const { value, comparison } = widgetData
      let valueMinutes = Math.round(value / 60)
      let comparisonMinutes = Math.round(comparison / 60)
      let minutes = comparisonMinutes - valueMinutes
      return comparison > 0 ? timeConversion(minutes) : value === 0 ? '0' : ''
    },
    selectReportValue: (state) => (id) => {
      return state.widgetData[id].value.toString()
    },
    selectReportValueAsPercent: (state) => (id) => {
      return state.widgetData[id].value.toString() + '%'
    },
    selectReportValueAsTime: (state) => (id) => {
      if (state.widgetData[id].value === null) return null
      let minutes = Math.round(state.widgetData[id].value / 60)
      return timeConversion(minutes)
    },
    selectWidgetList(state) {
      return state.widgetList
    },
    selectWidgetIds(state) {
      return state.widgetList.map((w) => w._id)
    },
    selectWidget: (state) => (id) => {
      return state.widgetList.find((w) => w._id === id)
    },
    selectReportValueObject: (state) => (id) => {
      return state.widgetData[id].value
    },
    selectObjectComparisonsAsPercents: (state) => (id) => {
      const widgetData = state.widgetData[id]
      const { value, comparison } = widgetData
      let comparisons = {}
      Object.keys(value).forEach((key) => {
        comparisons[key] =
          comparison[key] > 0
            ? Math.round(((value[key] - comparison[key]) / comparison[key]) * 100).toString() + '%'
            : value === 0
            ? '0'
            : ''
      })
      return comparisons
    },
    selectObjectComparisonsAsNumber: (state) => (id) => {
      const widgetData = state.widgetData[id]
      const { value, comparison } = widgetData
      let comparisons = {}
      Object.keys(value).forEach((key) => {
        comparisons[key] =
          comparison[key] > 0
            ? (value[key] - comparison[key]).toFixed(1).toString()
            : value === 0
            ? '0'
            : ''
      })
      return comparisons
    },
    selectTrendData: (state) => (metric) => {
      return state.trends[metric]
    },
    selectGranularity: (state) => state.granularity,
  },
  mutations: {
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    SET_WIDGET_DATA(state, { id, value, comparison }) {
      state.widgetData[id] = { value, comparison }
    },
    SET_SHOW_FILTERS_BAR(state, showBar) {
      state.showFiltersBar = showBar
    },
    INCREMENT_REFRESH_KEY(state) {
      state.refreshKey += 1
    },
    SET_TRENDS(state, trends) {
      state.trends = trends
    },
    SET_GRANULARITY(state, granularity) {
      state.granularity = granularity
    },
  },
  actions: {
    async initializeFilters({ commit, rootGetters }) {
      let filters = {}
      filters.companies =
        rootGetters['company/activeCompany']?._id == 'ALL_COMPANIES'
          ? rootGetters['company/companies'].map((c) => c._id)
          : [rootGetters['company/activeCompany']._id]
      filters.dateRange = [
        `${moment().subtract(30, 'd').startOf('day').utc().format()}`,
        `${moment().endOf('day').utc().format()}`,
      ]
      commit('SET_FILTERS', filters)
    },

    async toggleFiltersBar({ commit, getters }) {
      commit('SET_SHOW_FILTERS_BAR', !getters.selectShowFiltersBar)
    },

    async applyFilters({ commit, getters }, filters) {
      const { selectFilters } = getters
      if (isEqual(filters, selectFilters)) return
      commit('SET_FILTERS', filters)
      commit('INCREMENT_REFRESH_KEY')
    },

    async formatPayloadFilters({ commit, getters, rootGetters }) {
      const { companies, locations, dateRange, network } = getters.selectFilters
      let dateSpread = moment(dateRange[1]).diff(moment(dateRange[0]), 'd')
      let payload = {}

      payload.companyIds =
        companies && !companies.includes('ALL_COMPANIES')
          ? companies
          : rootGetters['company/companies'].map((c) => c._id)
      if (locations?.length) {
        payload.locationIds = locations
      } else {
        payload.locationIds = rootGetters['location/selectActiveLocations'].map((l) => l._id)
      }
      payload.createdAtRange = dateRange
      payload.compCreatedAtRange = [
        `${moment(dateRange[0]).subtract(dateSpread, 'd').startOf('day').utc().format()}`,
        `${moment(dateRange[1])
          .subtract(dateSpread + 1, 'd')
          .endOf('day')
          .utc()
          .format()}`,
      ]

      if (network) {
        const reviewSubscriptionIds = rootGetters[
          'location/selectLocationReviewSubscriptionsByNetwork'
        ](network, payload.locationIds)
        if (reviewSubscriptionIds?.length) {
          payload.reviewSubscriptionIds = reviewSubscriptionIds
        }
      }

      if (dateSpread <= 14) payload.granularity = 'day'
      else if (dateSpread <= 98) payload.granularity = 'week'
      else if (dateSpread <= 420) payload.granularity = 'month'
      else payload.granularity = 'year'

      commit('SET_GRANULARITY', payload.granularity)

      return payload
    },

    async formatDayparts({ getters, rootGetters }) {
      const daypartSetting = rootGetters['user/selectDayparts']
      const dayparts = []
      Object.keys(daypartSetting).forEach((key) => {
        dayparts.push({
          name: key,
          start: daypartSetting[key].start,
          end: daypartSetting[key].end,
        })
      })
      return dayparts
    },

    /******************************************************************************
     * Survey fetch functions
     ******************************************************************************/

    async fetchTrends({ commit, dispatch }) {
      commit('SET_TRENDS', {})
      const { companyIds, locationIds, createdAtRange, granularity } = await dispatch(
        'formatPayloadFilters'
      )

      const response = await DashboardService.fetchTrends({
        filters: { companyIds, locationIds, createdAtRange },
        granularity,
      })

      // populate missing date values
      const trends = response.body.data
      const endDate = moment(createdAtRange[1]).endOf(granularity)
      Object.keys(trends).map((metric) => {
        let currentDate = moment(createdAtRange[0]).startOf(granularity)
        let values = trends[metric]
        while (currentDate <= endDate) {
          const tempDate = moment(currentDate).format('YYYY-MM-DD')
          if (!values[tempDate]) {
            values[tempDate] = 0
          }
          currentDate = currentDate.add(1, granularity)
        }
      })

      commit('SET_TRENDS', trends)
    },

    async fetchSurveySentCount({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, createdAtRange, compCreatedAtRange } = await dispatch(
        'formatPayloadFilters'
      )

      const result = await DashboardService.surveySentCount({
        filters: {
          companyIds,
          locationIds,
          createdAtRange,
          previousCreatedAtRange: compCreatedAtRange,
        },
      })

      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: result.body.data.sentSurveyCount,
        comparison: result.body.data.previousSentSurveyCount,
      })
    },

    async fetchSurveyTakeRate({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, createdAtRange, compCreatedAtRange } = await dispatch(
        'formatPayloadFilters'
      )

      const result = await DashboardService.surveyTakeRate({
        filters: {
          companyIds,
          locationIds,
          createdAtRange,
          previousCreatedAtRange: compCreatedAtRange,
        },
      })
      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: result.body.data.takeRate,
        comparison: result.body.data.previousTakeRate,
      })
    },

    async fetchSurveyResponseRate({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, createdAtRange, compCreatedAtRange } = await dispatch(
        'formatPayloadFilters'
      )

      const result = await DashboardService.surveyResponseRate({
        filters: {
          companyIds,
          locationIds,
          createdAtRange,
          previousCreatedAtRange: compCreatedAtRange,
        },
      })
      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: result.body.data.responseRate,
        comparison: result.body.data.previousResponseRate,
      })
    },

    async fetchSurveyAverageRating({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, createdAtRange, compCreatedAtRange } = await dispatch(
        'formatPayloadFilters'
      )

      const result = await DashboardService.surveyAverageRating({
        filters: {
          companyIds,
          locationIds,
          createdAtRange,
          previousCreatedAtRange: compCreatedAtRange,
        },
      })
      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: result.body.data.averageRating,
        comparison: result.body.data.previousAverageRating,
      })
    },

    async fetchSurveyCompletedCount({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, createdAtRange, compCreatedAtRange } = await dispatch(
        'formatPayloadFilters'
      )

      const result = await DashboardService.surveyCompletedCount({
        filters: {
          companyIds,
          locationIds,
          createdAtRange,
          previousCreatedAtRange: compCreatedAtRange,
        },
      })
      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: result.body.data.completedSurveyCount,
        comparison: result.body.data.previousCompletedSurveyCount,
      })
    },

    async fetchSurveyAverageResponseTime({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, createdAtRange, compCreatedAtRange } = await dispatch(
        'formatPayloadFilters'
      )

      const result = await DashboardService.surveyAverageResponseTime({
        filters: {
          companyIds,
          locationIds,
          createdAtRange,
          previousCreatedAtRange: compCreatedAtRange,
        },
      })
      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: result.body.data.averageResponseTime,
        comparison: result.body.data.previousAverageResponseTime,
      })
    },

    async fetchSurveyScores({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, createdAtRange } = await dispatch('formatPayloadFilters')

      const response = await DashboardService.surveyScores({
        filters: { companyIds, locationIds, createdAtRange },
      })
      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: response.body.data.surveyScores,
      })
    },

    async fetchAverageRatingBySource({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, createdAtRange, compCreatedAtRange } = await dispatch(
        'formatPayloadFilters'
      )

      const result = await DashboardService.averageRatingBySource({
        filters: {
          companyIds,
          locationIds,
          createdAtRange,
          previousCreatedAtRange: compCreatedAtRange,
        },
      })
      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: result.body.data.averageRatingBySource,
        comparison: result.body.data.previousAverageRatingBySource,
      })
    },

    async fetchVolumeBySource({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, createdAtRange } = await dispatch('formatPayloadFilters')

      const response = await DashboardService.volumeBySource({
        filters: { companyIds, locationIds, createdAtRange },
      })
      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: response.body.data.volumesBySource,
      })
    },

    async fetchSatisfactionDayparts({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, createdAtRange } = await dispatch('formatPayloadFilters')

      const response = await DashboardService.satisfactionDayparts({
        filters: { companyIds, locationIds, createdAtRange },
        dayparts: await dispatch('formatDayparts'),
      })
      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: response.body.data.averageRatingByDaypart,
      })
    },

    /******************************************************************************
     * Customer fetch functions
     ******************************************************************************/

    async fetchCustomerCreatedCount({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, createdAtRange, compCreatedAtRange } = await dispatch(
        'formatPayloadFilters'
      )

      const result = await DashboardService.customerCreatedCount({
        filters: {
          companyIds,
          locationIds,
          createdAtRange,
          previousCreatedAtRange: compCreatedAtRange,
        },
      })
      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: result.body.data.customersCreatedCount,
        comparison: result.body.data.previousCustomersCreatedCount,
      })
    },
    async fetchCustomerSavedCount({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, createdAtRange, compCreatedAtRange } = await dispatch(
        'formatPayloadFilters'
      )

      const result = await DashboardService.customerSavedCount({
        filters: {
          companyIds,
          locationIds,
          createdAtRange,
          previousCreatedAtRange: compCreatedAtRange,
        },
      })

      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: result.body.data.customersSavedCount,
        comparison: result.body.data.customersSavedCount,
      })
    },

    /******************************************************************************
     * Review fetch functions
     ******************************************************************************/

    async fetchReviewCreatedCount({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, createdAtRange, compCreatedAtRange, reviewSubscriptionIds } =
        await dispatch('formatPayloadFilters')

      const result = await DashboardService.reviewCreatedCount({
        filters: {
          companyIds,
          locationIds,
          createdAtRange,
          previousCreatedAtRange: compCreatedAtRange,
          reviewSubscriptionIds,
        },
      })
      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: result.body.data.reviewsCreatedCount,
        comparison: result.body.data.previousReviewsCreatedCount,
      })
    },

    async fetchReviewAverageOnlineRating({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, reviewSubscriptionIds } = await dispatch(
        'formatPayloadFilters'
      )

      const response = await DashboardService.reviewAverageOnlineRating({
        filters: { companyIds, locationIds, reviewSubscriptionIds },
      })

      const { averageRating, snapshotAverage } = response.body.data

      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: averageRating,
        comparison: snapshotAverage,
      })
    },

    async fetchCurrentReviewsAndRatings({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, reviewSubscriptionIds } = await dispatch(
        'formatPayloadFilters'
      )

      const response = await DashboardService.currentReviewsAndRatings({
        filters: { companyIds, locationIds, reviewSubscriptionIds },
      })

      const { currentReviewAndRating } = response.body.data

      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: currentReviewAndRating,
      })
    },

    async fetchReviewsAndRatings({ commit, dispatch }, { widgetId, subGranularity }) {
      const { companyIds, locationIds, createdAtRange, granularity, reviewSubscriptionIds } =
        await dispatch('formatPayloadFilters')

      const response = await DashboardService.reviewsAndRatings({
        filters: { companyIds, locationIds, timestampRange: createdAtRange, reviewSubscriptionIds },
        granularity: subGranularity || granularity,
      })

      const { reviewsAndRatings } = response.body.data

      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: reviewsAndRatings,
      })
    },

    async fetchReviewsPerMonth({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, reviewSubscriptionIds } = await dispatch(
        'formatPayloadFilters'
      )

      const response = await DashboardService.reviewsPerMonth({
        filters: { companyIds, locationIds, reviewSubscriptionIds },
      })

      const { reviewsPerMonth } = response.body.data

      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: reviewsPerMonth.avgMonthlyReviews,
        comparison: reviewsPerMonth.preOvationAvgMonthlyReviews,
      })
    },

    async fetchClicksToReview({ commit, dispatch }, { widgetId }) {
      const { companyIds, locationIds, createdAtRange, compCreatedAtRange, reviewSubscriptionIds } =
        await dispatch('formatPayloadFilters')

      const result = await DashboardService.clicksToReview({
        filters: {
          companyIds,
          locationIds,
          createdAtRange,
          previousCreatedAtRange: compCreatedAtRange,
          reviewSubscriptionIds,
        },
      })

      commit('SET_WIDGET_DATA', {
        id: widgetId,
        value: result.body.data.clicksToReview,
        comparison: result.body.data.previousClicksToReview,
      })
    },
  },
}
