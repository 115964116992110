<template>
  <div v-if="showCognitoLogin === true">
    <amplify-authenticator username-alias="email">
      <amplify-sign-in slot="sign-in" :hide-sign-up="true" username-alias="email"></amplify-sign-in>
    </amplify-authenticator>
  </div>
  <div v-else>
    <h1 class="display-4 text-center mb-3">Login</h1>
    <form @submit.prevent="nextLoginStep">
      <div class="form-group">
        <label>Mobile Number</label>
        <input
          autofocus
          type="text"
          v-model="phone"
          class="form-control"
          placeholder="(000) 000-0000"
          v-mask="'(###) ###-####'"
          autocomplete="tel"
          :disabled="isVerifyingPhone"
        />
      </div>
      <SlideUpTransition>
        <div v-if="isVerifyingPhone && !errMessage" class="form-group">
          <label>What is the code sent to {{ phone | phone }}?</label>
          <input
            ref="codeInput"
            v-model="phoneCode"
            type="tel"
            class="form-control"
            placeholder="123456"
          />
        </div>

        <div v-if="errMessage" class="alert alert-danger" role="alert">{{ errMessage }}</div>
      </SlideUpTransition>

      <button
        type="submit"
        :disabled="!phone || isLoading || (isVerifyingPhone && phoneCode.length < 6)"
        :class="{ 'is-loading': isLoading || (isVerifyingPhone && phoneCode.length < 6) }"
        class="btn btn-lg btn-block btn-primary mb-3"
      >
        Login
      </button>
      <div class="text-center">
        <small class="text-muted text-center">
          Don't have an account yet?
          <a href="https://ovationup.com/demo-request/" target="_blank">Sign up</a>.
        </small>
      </div>
      <div class="text-center">
        <small class="text-muted text-center">
          <a @click="showCognitoLogin = !showCognitoLogin" style="cursor: pointer; color: #2c7be5"
            >Login with email</a
          >.
        </small>
      </div>
    </form>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import SlideUpTransition from '@/components/Transitions/SlideUpTransition'

export default {
  name: 'LoginForm',
  components: {
    SlideUpTransition,
  },
  data() {
    return {
      isLoading: false,
      errMessage: false,
      phone: '',
      phoneCode: '',
      isVerifyingPhone: false,
      showCognitoLogin: false,
    }
  },
  methods: {
    nextLoginStep() {
      if (!this.isVerifyingPhone) {
        this.sendPhoneVerification()
      } else if (this.isVerifyingPhone) {
        this.verifyPhoneCode()
      }
    },
    async sendPhoneVerification() {
      this.errMessage = ''
      this.isLoading = true

      try {
        await UserService.sendPhoneVerification({
          phone: this.phone,
          isLogin: true,
          webOrigin: window.location.origin,
        })
        this.isVerifyingPhone = true

        if (this.$refs.codeInput) {
          this.$refs.codeInput.focus()
        }
      } catch (err) {
        this.errMessage = err.body.message
        this.isVerifyingPhone = false
      } finally {
        this.isLoading = false
      }
    },
    async verifyPhoneCode() {
      try {
        this.isLoading = true

        const response = await UserService.verifyPhoneCode({
          code: this.phoneCode,
        })

        const { jwt } = response.body
        localStorage.setItem('jwt', jwt)

        this.$emit('authenticated')
      } catch (err) {
        this.isLoading = false
        this.errMessage = err.body.message
        this.isVerifyingPhone = false
        this.phoneCode = ''
      }
    },
  },
}
</script>

<style scoped lang="scss">
.password-mask:hover {
  cursor: pointer;
}
</style>
