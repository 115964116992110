import Vue from 'vue'
import Router from 'vue-router'
import { bootIntercom } from '@/lib/analytics'
import UserService from '@/services/UserService'
import { setUserActiveCompanyId } from '@/store/company'
import LandingPage from './pages/LandingPage'
import LoginPage from './pages/LoginPage'
import WinbackWebviewPage from './pages/WinbackWebviewPage'
import LinkMissingAccountInfoPage from './pages/LinkMissingAccountInfoPage'
import InvitePage from './pages/InvitePage'

import CompanySettingsProfile from './components/Modules/Company/CompanySettings/CompanySettingsProfile'
import CompanySettingsLocations from './components/Modules/Company/CompanySettings/CompanySettingsLocations/CompanySettingsLocations'
import CompanySettingsTeam from './components/Modules/Company/CompanySettings/CompanySettingsTeam/CompanySettingsTeam'
import CompanySettingsIntegrations from './components/Modules/Company/CompanySettings/CompanySettingsIntegrations'
import CompanySettingsSubscription from './components/Modules/Company/CompanySettings/CompanySettingsSubscription/CompanySettingsSubscription'
import CompanySettingsLoyalty from './components/Modules/Company/CompanySettings/CompanySettingsLoyalty/CompanySettingsLoyalty'
import CompanySettingsSurveyLinks from './components/Modules/Company/CompanySettings/CompanySettingsSurveyLinks'
import CompanySettingsResponses from './components/Modules/Company/CompanySettings/CompanySettingsResponses/CompanySettingsResponses'
//import CompanySettingsTags from './components/Modules/Company/CompanySettings/CompanySettingsTags/CompanySettingsTags'

import LocationSettingsSurvey from './components/Modules/Location/LocationSettings/LocationSettingsSurvey'
import LocationSettingsPlatforms from './components/Modules/Location/LocationSettings/LocationSettingsPlatforms/LocationSettingsPlatforms'
import LocationSettingsDevices from './components/Modules/Location/LocationSettings/LocationSettingsDevices/LocationSettingsDevices'
import LocationSettingsIntegrations from './components/Modules/Location/LocationSettings/LocationSettingsIntegrations/LocationSettingsIntegrations'
import LocationSettingsBasic from './components/Modules/Location/LocationSettings/LocationSettingsBasic/LocationSettingsBasic'

import AccountProfileForm from '@/components/Modules/User/Account/AccountProfileForm'
import AccountNotificationSettings from '@/components/Modules/User/Account/AccountNotificationSettings'

import QuestionsView from '@/components/Modules/CustomQuestions/QuestionsView'
import ReportsView from '@/components/Modules/CustomQuestions/ReportsView'
import SettingsView from '@/components/Modules/CustomQuestions/SettingsView'

import CTTReportsView from '@/components/Modules/CallToText/Views/ReportsView'
import CTTSettingsView from '@/components/Modules/CallToText/Views/SettingsView'

import GiftCardReportsView from '@/components/Modules/GiftCard/Views/ReportsView'

import InsightsSummary from '@/components/Modules/Insights/SummarySection/SummaryView'
import InsightsDetails from '@/components/Modules/Insights/DetailsSection/DetailsView'

import ReportDashboard from '@/components/Modules/Report/Dashboard/DashboardView'
import ReportDashboardBuilder from '@/components/Modules/Report/Builder/BuilderView'

Vue.use(Router)

export const routes = [
  {
    path: '/',
    name: 'landing',
    component: LandingPage,
    meta: {
      hideNav: true,
    },
  },
  {
    path: '/invite/:token',
    name: 'invite',
    component: InvitePage,
    meta: {
      hideNav: true,
      public: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      hideNav: true,
    },
  },
  {
    path: '/m/winback/:conversationId',
    name: 'winback-webview',
    component: WinbackWebviewPage,
    meta: {
      hideNav: true,
      public: true,
    },
  },
  {
    path: '/add-info',
    name: 'add-info',
    component: LinkMissingAccountInfoPage,
    meta: {
      hideNav: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ './pages/DashboardPage.vue'),
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: () => import(/* webpackChunkName: "review" */ './pages/ReviewsPage.vue'),
  },
  {
    path: '/loyalty',
    name: 'loyalty',
    component: () => import(/* webpackChunkName: "review" */ './pages/LoyaltyPage.vue'),
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: () => import(/* webpackChunkName: "company" */ './pages/LeaderboardPage.vue'),
  },
  {
    path: '/surveys',
    name: 'surveys',
    component: () => import(/* webpackChunkName: "survey" */ './pages/SurveysPage.vue'),
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import(/* webpackChunkName: "customer" */ './pages/CustomersPage.vue'),
  },
  {
    path: '/customers/import',
    name: 'customers-import',
    component: () =>
      import(/* webpackChunkName: "customers-import" */ './pages/CustomersImportPage.vue'),
  },
  {
    path: '/customers/add',
    name: 'customers-add',
    component: () => import(/* webpackChunkName: "customers-add" */ './pages/CustomersAddPage.vue'),
  },
  {
    path: '/create-message',
    name: 'create-message',
    component: () =>
      import(/* webpackChunkName: "create-message" */ './pages/CreateMessagePage.vue'),
  },
  {
    path: '/custom-questions',
    name: 'custom-questions',
    children: [
      {
        path: 'reports',
        name: 'custom-questions-reports',
        component: ReportsView,
      },
      {
        path: 'questions',
        name: 'custom-questions-questions',
        component: QuestionsView,
      },
      {
        path: 'settings',
        name: 'custom-questions-settings',
        component: SettingsView,
      },
    ],
    component: () =>
      import(/* webpackChunkName: "custom-questions" */ './pages/CustomQuestionsPage.vue'),
  },
  {
    path: '/calls',
    name: 'Call-to-Text',
    children: [
      {
        path: 'reports',
        name: 'call-reports',
        component: CTTReportsView,
      },
      {
        path: 'settings',
        name: 'call-settings',
        component: CTTSettingsView,
      },
    ],
    component: () => import(/* webpackChunkName: "custom-questions" */ './pages/CallsPage.vue'),
  },
  {
    path: '/gift-cards',
    name: 'Gift-Cards',
    children: [
      {
        path: 'reports',
        name: 'gift-card-reports',
        component: GiftCardReportsView,
      },
    ],
    component: () => import(/* webpackChunkName: "custom-questions" */ './pages/GiftCardPage.vue'),
  },
  {
    path: '/account',
    name: 'account',
    children: [
      {
        path: 'profile',
        name: 'account-profile',
        component: AccountProfileForm,
      },
      {
        path: 'notifications',
        name: 'account-notifications',
        component: AccountNotificationSettings,
      },
    ],
    component: () => import(/* webpackChunkName: "user" */ './pages/AccountPage.vue'),
  },
  {
    path: '/winback',
    name: 'winback',
    children: [
      {
        path: ':conversationId',
        name: 'winback-conversation',
      },
    ],
    component: () => import(/* webpackChunkName: "winback" */ './pages/WinbackPage.vue'),
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    component: () => import(/* webpackChunkName: "campaigns" */ './pages/CampaignsPage.vue'),
  },
  {
    path: '/create-campaign',
    name: 'create-campaign',
    component: () =>
      import(/* webpackChunkName: "create-campaign" */ './pages/CreateCampaignPage.vue'),
  },
  {
    path: '/promos',
    name: 'promos',
    component: () => import(/* webpackChunkName: "promos" */ './pages/PromosPage.vue'),
  },
  {
    path: '/marqii',
    name: 'marqii',
    component: () => import(/* webpackChunkName: "marqii" */ './pages/MarqiiPage.vue'),
  },
  {
    path: '/create-promo',
    name: 'create-promo',
    component: () => import(/* webpackChunkName: "create-promo" */ './pages/CreatePromoPage.vue'),
  },
  {
    path: '/keywords',
    name: 'keywords',
    component: () => import(/* webpackChunkName: "keywords" */ './pages/KeywordsPage.vue'),
  },
  {
    path: '/create-keyword',
    name: 'create-keyword',
    component: () =>
      import(/* webpackChunkName: "create-keyword" */ './pages/CreateKeywordPage.vue'),
  },
  {
    path: '/templates',
    name: 'templates',
    component: () => import(/* webpackChunkName: "templates" */ './pages/TemplatesPage.vue'),
  },
  {
    path: '/create-template',
    name: 'create-template',
    component: () =>
      import(/* webpackChunkName: "create-template" */ './pages/CreateTemplatePage.vue'),
  },
  {
    path: '/summary',
    redirect: '/',
  },
  {
    path: '/insights',
    name: 'insights',
    children: [
      {
        path: 'summary',
        name: 'insights-summary',
        component: InsightsSummary,
      },
      {
        path: 'details',
        name: 'insights-details',
        component: InsightsDetails,
      },
    ],
    component: () => import(/* webpackChunkName: "insights" */ './pages/InsightsPage.vue'),
  },
  {
    path: '/oauth/:integrationProviderName',
    name: 'oauth',
    component: () => import(/* webpackChunkName: "oauth-page" */ './pages/OauthPage.vue'),
  },
  {
    path: '/location-settings/:locationId',
    name: 'location-settings',
    children: [
      {
        path: 'survey',
        name: 'location-settings-survey',
        component: LocationSettingsSurvey,
      },
      {
        path: 'platforms',
        name: 'location-settings-platforms',
        component: LocationSettingsPlatforms,
      },
      {
        path: 'devices',
        name: 'location-settings-devices',
        component: LocationSettingsDevices,
      },
      {
        path: 'integrations',
        name: 'location-settings-integrations',
        component: LocationSettingsIntegrations,
      },
      {
        path: 'basic',
        name: 'location-settings-basic',
        component: LocationSettingsBasic,
      },
    ],
    component: () => import(/* webpackChunkName: "location" */ './pages/LocationSettingsPage.vue'),
  },
  {
    path: '/company-settings',
    name: 'company-settings',
    children: [
      {
        path: 'profile',
        name: 'company-settings-profile',
        component: CompanySettingsProfile,
      },
      {
        path: 'loyalty',
        name: 'company-settings-loyalty',
        component: CompanySettingsLoyalty,
      },
      {
        path: 'locations',
        name: 'company-settings-locations',
        component: CompanySettingsLocations,
      },
      {
        path: 'links',
        name: 'company-settings-links',
        component: CompanySettingsSurveyLinks,
      },
      {
        path: 'team',
        name: 'company-settings-team',
        component: CompanySettingsTeam,
      },
      {
        path: 'integrations',
        name: 'company-settings-integrations',
        component: CompanySettingsIntegrations,
      },
      {
        path: 'subscription',
        name: 'company-settings-subscription',
        component: CompanySettingsSubscription,
      },
      {
        path: 'responses',
        name: 'company-settings-responses',
        component: CompanySettingsResponses,
      },
      // {
      //   path: 'tags',
      //   name: 'company-settings-tags',
      //   component: CompanySettingsTags,
      // },
    ],
    component: () => import(/* webpackChunkName: "company" */ './pages/CompanyPage.vue'),
  },
  {
    path: '/add-location',
    name: 'add-location',
    component: () => import(/* webpackChunkName: "location" */ './pages/AddLocationPage.vue'),
  },
  {
    path: '/report-builder',
    name: 'report-builder',
    component: () =>
      import(/* webpackChunkName: "report-builder" */ './pages/ReportBuilderPage.vue'),
  },
  {
    path: '/reports',
    name: 'reports',
    children: [
      {
        path: 'dashboard',
        name: 'report-dashboard',
        component: ReportDashboard,
      },
      {
        path: 'builder',
        name: 'report-dashboard-builder',
        component: ReportDashboardBuilder,
      },
    ],
    component: () => import(/* webpackChunkName: "report" */ './pages/ReportPage.vue'),
  },
]

export default new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  base: process.env.BASE_URL,
  routes,
})

export async function initRouterView(context) {
  if (window.isNativeApp && context.$route.query) {
    const { token } = context.$route.query
    if (token) {
      window.localStorage.setItem('jwt', token)
    }
  } else {
    bootIntercom(context.$intercom)
  }

  let response
  try {
    response = await UserService.get()
  } catch (error) {
    const { status } = error
    response = { body: { notOnline: true, user: null, partner: null } }
    context.$store.dispatch('resetSettings')

    if (
      status === 401 &&
      context.$route.matched &&
      !context.$route.matched.some((record) => record.meta.public)
    ) {
      document.title = 'Login'
      context.$router.replace({ name: 'login' })
      return
    }
  }
  const { notOnline, user, partner } = response.body
  const { name: startRoute } = context.$route

  if (
    (notOnline && startRoute === 'landing') ||
    (!user &&
      context.$route.matched &&
      !context.$route.matched.some((record) => record.meta.public))
  ) {
    context.$store.dispatch('resetSettings')
    document.title = 'Login'
    context.$router.push('login')
  } else if (user) {
    await context.$store.dispatch('setConfig')
    await Promise.all([
      context.$store.dispatch('user/getOnlineUser'),
      context.$store.dispatch('user/fetchAuthorizedUser'),
    ])

    const needsInfo = context.$store.state.user.needsInfo

    if (context.companyId) {
      setUserActiveCompanyId(context.$store.state.user.onlineUser._id, context.companyId)
    }

    context.$store.dispatch('integrationProvider/getIntegrationProviders')
    context.$store.dispatch('dataCore/init')

    if (startRoute === 'landing' || startRoute === 'login' || startRoute === 'add-info') {
      if (needsInfo) {
        context.$router.push('add-info')
      } else {
        context.$router.push('dashboard')
      }
    }

    if (startRoute === 'invite') {
      context.$router.replace('/dashboard')
    }
  }
  context.$store.dispatch('user/fetchCompaniesUsersList')

  brandApp({ context, partner })
}

export function brandApp({ context, partner }) {
  const partnerName = partner && partner.name
  const partnerFaviconLogo = partner && partner.faviconLogo

  document.title = partnerName || context.$store.getters['user/brandName']
  document.querySelectorAll("link[rel*='icon']").forEach((node) => node.remove())

  const link = document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'icon'
  link.href = partnerFaviconLogo || context.$store.getters['user/brandFaviconLogo']
  document.getElementsByTagName('head')[0].appendChild(link)
}

export function getUrlParameter(name) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  var results = regex.exec(window.location.search)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}
