import Vue from 'vue'

export default {
  fetchClassifications(payload) {
    return Vue.http.post('app-services/v2/insight-classification/search', payload)
  },
  fetchCategories() {
    return Vue.http.get('app-services/v2/insight-category/list')
  },
  fetchFeedbackCount({ filters }) {
    return Vue.http.post('app-services/v2/insight-classification/feedback/count', { filters })
  },
  fetchPopulatedClassifications({ filters, search, limit, skip }) {
    return Vue.http.post('app-services/v2/insight-classification/search-detailed', {
      filters,
      search,
      limit,
      skip,
    })
  },
  fetchFeedbackCountBreakdown({ filters }) {
    return Vue.http.post('app-services/v2/insight-classification/feedback/count-breakdown', {
      filters,
    })
  },
  fetchSummaries({ filters, search }) {
    return Vue.http.post('app-services/v2/insight-classification/summary', { filters, search })
  },
}
